import React from 'react';
import './Header.css';
import logo from './logo.png';

const Header = ({ userName, activeTab }) => {
  const tabs = [
    "پرونده شخصی",
    "بازبینی",
    "احراز هویت",
    "انتظار",
  ];

  const activeIndex = tabs.indexOf(activeTab);

  // اگر در تب اول باشیم، مقدار progress صفر باشد
  const progress = activeTab === "پرونده شخصی"
  ? 0
  : activeTab === "بازبینی"
    ? 33
    : activeTab === "احراز هویت"
      ? 67
      : activeTab === "انتظار"
        ? 100
        : 0; // اگر تب نامعتبر باشد، نوار پیشرفت خالی می‌ماند

  const remainingTabs = tabs.length - (activeIndex + 1);

  return (
    <header className="header">
      <div className="user-name">{userName}</div>

      {/* نوار لودینگ */}
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div className="progress-fill" style={{ width: `${progress}%` }}></div>

          {/* نقاط مربوط به تب‌ها */}
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`progress-marker ${index === activeIndex ? 'active' : ''}`}
              style={{ right: `${(index / (tabs.length - 1)) * 100}%` }}
            />
          ))}
        </div>

        {/* نمایش نام تب فعال زیر نقطه مربوطه */}
        <div
          className="active-tab-name"
          style={{
            right: `calc(${(activeIndex / (tabs.length - 1)) * 100}% + 10px)`, 
            transform: "translateX(50%)"
          }}
        >
          {activeTab}
        </div>

      </div>

      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
    </header>
  );
};


export default Header;
