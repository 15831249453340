import React from 'react';
import './Loading.css'; // استایل‌هایی که برای لودینگ نوشته‌اید

const Loading = () => {
  return (
    <div className='loading-back'>
    <div className="container">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      </div>
    </div>
  );
};

export default Loading;
