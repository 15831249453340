import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './VideoPage.css';

const desktopOrder = [
  { IMG: '/images/khalifa.png', imageSrc: '/images/UAE.png', country: 'UAE', title: 'مرحبا', subtitle: "ومرحبًا بكم في القلب المحرك لصناعة إيران، هولدينغ باترا" },
  { IMG: '/images/turkey.jpg', imageSrc: '/images/turkey.png', country: 'Turkey', title: 'Selamlar', subtitle: "İran sanayisinin itici kalbine, Patra Holding'e hoş geldiniz" },
  { IMG: '/images/irann.jpg', imageSrc: '/images/iran.png', country: 'Iran', title: 'درود بر شما', subtitle: "به قلب محرک صنعت ایران هلدینگ پاترا، خوش آمدید" },
  { IMG: '/images/engel.jpg', imageSrc: '/images/england.png', country: 'England', title: 'Greetings', subtitle: "Welcome to the driving heart of Iran's industry, Patra Holding" },
  { IMG: '/images/kurdis.jpg', imageSrc: '/images/kurdistan.png', country: 'Kurdistan', title: "رێز و رۆژ باش", subtitle: "بەخێر بێن بۆ هێرشا دڵی پێشێلکاری ئێران، هاوپەیمانی پاترا" },
];

const mobileOrder = [
  { IMG: '/images/irann.jpg', imageSrc: '/images/iran.png', country: 'Iran', title: 'درود بر شما', subtitle: "به قلب محرک صنعت ایران هلدینگ پاترا، خوش آمدید" },
  { IMG: '/images/khalifa.png', imageSrc: '/images/UAE.png', country: 'UAE', title: 'مرحبا', subtitle: "ومرحبًا بكم في القلب المحرك لصناعة إيران، هولدينغ باترا" },
  { IMG: '/images/turkey.jpg', imageSrc: '/images/turkey.png', country: 'Turkey', title: 'Selamlar', subtitle: "İran sanayisinin itici kalbine, Patra Holding'e hoş geldiniz" },
  { IMG: '/images/engel.jpg', imageSrc: '/images/england.png', country: 'England', title: 'Greetings', subtitle: "Welcome to the driving heart of Iran's industry, Patra Holding" },
  { IMG: '/images/kurdis.jpg', imageSrc: '/images/kurdistan.png', country: 'Kurdistan', title: "رێز و رۆژ باش", subtitle: "بەخێر بێن بۆ هێرشا دڵی پێشێلکاری ئێران، هاوپەیمانی پاترا" },
];

function ImagePage() {
  const [activeIndex, setActiveIndex] = useState(null);
  const isMobile = window.innerWidth <= 768;
  const images = isMobile ? mobileOrder : desktopOrder;
  const containerRef = useRef(null);
  const hoverTimeoutRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    if (!isMobile) {
      hoverTimeoutRef.current = setTimeout(() => {
        setActiveIndex(index);
      }, 200);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      clearTimeout(hoverTimeoutRef.current);
      setActiveIndex(null);
    }
  };

  const handleClick = (index) => {
    if (isMobile) {
      setActiveIndex(activeIndex === index ? null : index);
    }
  };

  const handleCardClick = (country) => {
    navigate(`/${country}`);
  };

  const handleOutsideClick = (event) => {
    if (isMobile && activeIndex !== null && containerRef.current && !containerRef.current.contains(event.target.closest('.image-card2'))) {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      clearTimeout(hoverTimeoutRef.current);
    };
  }, [activeIndex, isMobile]);

  return (
    <div className={`image-container2 ${isMobile && activeIndex !== null ? 'active-mode2' : ''}`} ref={containerRef}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`image-card2 ${activeIndex === index ? 'active2' : activeIndex !== null ? 'hidden2' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          <img
            src={image.IMG}
            alt={`${image.country}`}
            className="image-element2"
          />
          {activeIndex === index && (
            <div className="overlay2" onClick={() => handleCardClick(image.country)}>
              <img src={image.imageSrc} alt={`${image.country} flag`} className="country-image2" />
              <h2>{image.title}</h2>
              <p>{image.subtitle}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ImagePage;
