import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import SecondPage from './components/SecondPage';
import NextPage from './components/NextPage';
import UploadVideo from './components/UploadVideo';
import Wait from './components/wait';
import Dashboard from './components/Dashboard';
import SubscriptionCode from './components/SubscriptionCode';
import Login from './components/Login';
import ImagePage from './components/Swiper';
import AboutPage from './components/AboutPage';
import AboutPageen from './components/AboutPage-en.js';
import AboutPagetr from './components/AboutPage-tr.js';
import AboutPagear from './components/AboutPagear';
import AboutPageku from './components/AboutPage-ku.js';
import './App.css';

function App() {
    const [userName, setUserName] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleLogin = (phoneNumber) => {
        setPhoneNumber(phoneNumber);
        setLoggedIn(true);
    };

    useEffect(() => {
        const fetchUserName = async () => {
            try {
                const response = await fetch(`http://localhost:5000/api/get-user-name?phone_number=${phoneNumber}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                setUserName(data.userName);
            } catch (error) {
                console.error('Error:', error);
                alert(`مشکلی پیش آمد: ${error.message}`);
            }
        };
        if (loggedIn && phoneNumber) {
            fetchUserName();
        }
    }, [loggedIn, phoneNumber]);
    const appStyle = {
        height: '100%',
        backgroundColor: 'blue'
    };
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LoginForm onLogin={handleLogin} />} />
                    <Route path="/login" element={<Login />}/>
                    <Route path="/second-page" element={<SecondPage userName={userName} phoneNumber={phoneNumber} />} />
                    <Route path="/next-page" element={<NextPage />}/>
                    <Route path="/upload-video" element={<UploadVideo />}/>
                    <Route path="/wait" element={<Wait />}/>
                    <Route path="/dashboard" element={<Dashboard />}/>
                    <Route path="/code" element={<SubscriptionCode />}/>
                    <Route path="/gifs" element={<ImagePage />} />
                    <Route path="/iran" element={<AboutPage />} />
                    <Route path="/england" element={<AboutPageen />} />
                    <Route path="/turkey" element={<AboutPagetr />} />
                    <Route path="/uae" element={<AboutPagear />} />
<Route path="/kurdistan" element={<AboutPageku />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
