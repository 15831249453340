// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Footer.css */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px 0;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    font-weight: 600;
    width: 100%;
    height: 40px;
    max-width: 1920px;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    text-align: center;

}

p {

    margin-top: 15px;

}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oCAAoC;IACpC,eAAe;IACf,0BAA0B;IAC1B,kCAAkC;IAClC,8CAA8C;IAC9C,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,kBAAkB;;AAEtB;;AAEA;;IAEI,gBAAgB;;AAEpB","sourcesContent":["/* src/components/Footer.css */\r\n.footer {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background: rgba(255, 255, 255, 0.2);\r\n    padding: 10px 0;\r\n    backdrop-filter: blur(8px);\r\n    -webkit-backdrop-filter: blur(8px);\r\n    border-top: 1px solid rgba(255, 255, 255, 0.5);\r\n    color: #fff;\r\n    font-weight: 600;\r\n    width: 100%;\r\n    height: 40px;\r\n    max-width: 1920px;\r\n    margin: 0 auto;\r\n    position: fixed;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    z-index: 1000;\r\n    text-align: center;\r\n\r\n}\r\n\r\np {\r\n\r\n    margin-top: 15px;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
