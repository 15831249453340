import React from 'react';
import './popup.css';

const Toast = ({ message1, message2, onClose }) => (
    <div className="toast active">
        <div className="toast-content">
            <div className="check">
                <i className="fas fa-check"></i>
            </div>
            <div className="message2">
                <span className="text text-1">{message1}</span>
                <span className="text text-2">{message2}</span>
            </div>
        </div>
        <i className="fas fa-times close" onClick={onClose}></i>
        <div className="progress active"></div>
    </div>
);

export default Toast;
