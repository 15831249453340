import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './AboutPage.css';
import { Autoplay } from 'swiper/modules';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
const partners = [
  {
    image: "/images/post-s.png",
    link: "https://www.post.ir/"
  },
  {
    image: "/images/seda-va-sima.png",
    link: "https://www.pririb.ir"
  },
  {
    image: "/images/Mli-Bank.png",
    link: "https://www.bmi.ir/"
  },
  {
    image: "/images/Esteghlal Malasani.png",
    link: "https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87_%D9%81%D9%88%D8%AA%D8%A8%D8%A7%D9%84_%D8%A7%D8%B3%D8%AA%D9%82%D9%84%D8%A7%D9%84_%D9%85%D9%84%D8%A7%D8%AB%D8%A7%D9%86%DB%8C"
  },
  {
    image: "/images/Nedex.png",
    link: "https://nedex.ir/"
  },
  {
    image: "/images/Ariyana.png",
    link: ""
  }
];
const company = [
  {
    image: "/images/Bim.png",
    name: "بیمچه",
    position: "",
    description: "نظرًا لأهمية هذا المستند، تُعد بیمچه كعلامة تجارية واحدة من اللاعبين الرائدين في هذا القطاع، وتهدف إلى تعزيز رضا المستخدم من خلال تسهيل الخدمات عبر المنصات الرقمية.",
    links: {
      website: "http://banian-vp.com",
    }
  },
  {
    image: "/images/Sav.png",
    name: "ساو",
    position: "",
    description: "نظرًا لأهمية ولاء العملاء والبيئة التنافسية الشديدة في معظم الصناعات، يمكن اعتبار إنشاء نادي للعملاء أمرًا لا مفر منه في قطاع الخدمات. شركة باترا رهرو الإيرانية للتجارة قامت بتأسيس شركة تابعة لتوفير مرافق ملائمة لجميع عملائها للاستفادة من الخدمات خارج سلسلة القيمة الخاصة بباترا رهرو.",
    links: {
      website: "https://www.iransavclub.com",
    }
  },
  {
    image: "/images/VarmiHome.png",
    name: "فارمي",
    position: "",
    description: "بذلت شركة فارمي كل جهد ممكن لتصنيع منتجاتها بأعلى جودة وتصميم عصري. تعمل فارمي منذ عدة سنوات في صناعة أدوات المطبخ وتسعى لتوسيع نطاق منتجاتها.",
    links: {
      website: "https://varmihome.ir",
    }
  },
  {
    image: "/images/CityKitchen.png",
    name: "مدينة المطبخ",
    position: "",
    description: "تعمل هذه الشركة في مجال التسويق عبر الهاتف، وتضم أكثر من 400 ممثل مبيعات عبر الهاتف، وتبيع منتجات منزلية وعناية شخصية.",
    links: {
      website: "https://shahreashpazkhaneh.ir",
    }
  },
  {
    image: "/images/LakPosht.png",
    name: "لاكبوشت",
    position: "",
    description: "تعمل شركة لاكبوشت في توزيع البضائع والمنتجات في أكثر من 170 مدينة بمساعدة حوالي 300 من مندوبي التوصيل، وتعاون مع شركات التوزيع الأخرى وشركة البريد لجمهورية إيران الإسلامية.",
    links: {
      website: "lakpost.ir",
    }
  },
  {
    image: "/images/PatraTrade.png",
    name: "باترا تريد",
    position: "",
    description: "باعتبارها شركة جديدة التأسيس، تتولى هذه المؤسسة مسؤولية الأنشطة التجارية والدولية للمجموعة.",
    links: {
      website: "https://patratrade.com/",
    }
  }
];


const characters = [
  {
    image: "/images/Parkhande.jpg",
    name: "طاها پرخنده",
    position: "مدیر عامل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "پیام نانکلی",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر راد سعید",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر ابراهیمی",
    position: "وکیل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "سبحان لاله",
    position: " ",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  // بقیه داده‌های کاراکترها
];
function Sidebar({ activeTab, onTabClick }) {
  const tabs = ["قصتنا", "الشركات الفرعية", "الشركاء التجاريون", "بيان المهمة", "بيان الرؤية", "بيان القيم", "مجلس الإدارة", "اتصل بنا"];
  return (
    <div className="sidebar-ab">
      <div
        className="progress-line-ab"
        style={{ height: `${((activeTab + 1) / tabs.length) * 100}%` }}
      ></div>
      <div className="tabs-ab">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-ab ${index === activeTab ? "active-ab" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

function AboutPagear() {
  const [activeTab, setActiveTab] = useState(0);
  const brandStoryRef = useRef(null);
  const subCompanysRef = useRef(null);
  const partnersRef = useRef(null); // مرجع برای بخش شرکای تجاری
  const boardRef = useRef(null);
  const MissionsRef = useRef(null);
  const VissionsRef = useRef(null);
  const ValuesRef = useRef(null);
  const prsons = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
      const handleScroll = () => {
        const sections = [
          brandStoryRef.current,
          subCompanysRef.current,
          partnersRef.current,
          boardRef.current,
          MissionsRef.current,
          VissionsRef.current,
          ValuesRef.current,
          prsons.current,
          contactRef.current,
        ];
    
        // فیلتر کردن بخش‌هایی که مقداردهی نشده‌اند
        const sectionOffsets = sections
          .filter(section => section) // حذف بخش‌های null
          .map(section => section.getBoundingClientRect().top);
    
        const activeIndex = sectionOffsets.findIndex(
          offset => offset >= 0 && offset < window.innerHeight / 2
        );
    
        if (activeIndex !== -1) {
          setActiveTab(activeIndex);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTabClick = (index) => {
      const refs = [brandStoryRef, subCompanysRef, partnersRef, MissionsRef, VissionsRef, ValuesRef, prsons, contactRef];

      
      // بررسی وجود ref قبل از scrollIntoView
      if (refs[index] && refs[index].current) {
        refs[index].current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Ref at index ${index} is not defined.`);
      }
    };
  return (
    <div className="All">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />

<div ref={brandStoryRef} className="brand-story">
  
        <p className="s-sub-title">قصتنا</p>
        <div className="brand-story-item">
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
          <p className="brand-story-text">
          بدأت هذه الشركة نشاطها في التسوق عبر التلفاز منذ عام 2014، واستمرت ببيع منتجات متنوعة في مجالات مثل الملابس، والمستلزمات الرياضية، والمنتجات التجميلية، والأدوات المنزلية.
            <br /><br />
            از سال ۱۳۹۹ با نام تجاری پایه تجارت رهرو ایرانیان پا به عرصه تولید گذاشته و در زمینه تولید ظروف تفلون و لوازم برقی خانگی قدم دیگری در راستای تکمیل زنجیره ارزش خود برداشته.
          </p>
        </div>

        <div className="brand-story-item">
          <p className="brand-story-text">
          منذ عام 2020، تحت اسم العلامة التجارية "تجارة الراهر الإيراني"، دخلت الشركة مجال الإنتاج، لتخطو خطوة أخرى نحو إكمال سلسلة قيمتها بإنتاج أواني التفلون والأجهزة المنزلية.
          </p>
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
        </div>
      </div>

 
      <div ref={subCompanysRef} className="sub-companys">
      <div className="container123">
        <p className="s-sub-title">الشركات الفرعية</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    3000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    2000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
          className="cards-container-sb"
        >
          {company.map((company, index) => (
            <SwiperSlide key={index}>
              <div className="card-sb">
    <img src={company.image} alt="Profile" className="profile-image-sb" />
    <h2 className="name-sb">{company.name}</h2>
    <p className="description-sb">{company.description}</p>
    <div className="website-button">
        <a href={company.links.website} target="_blank" rel="noopener noreferrer" className="site-link">
            <button className="site-button">
                <i className="fas fa-globe site-icon"></i>
            </button>
        </a>
    </div>
</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
      <div ref={partnersRef} className="partners-section">
        <p className="s-sub-title">الشركاء التجاريون</p>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={partner.image} alt={`Partner ${index + 1}`} className="partner-logo" />
            </a>
          ))}
        </div>
      </div>
      <div ref={MissionsRef} className="Missions-section">
        <p className="s-sub-title">بيان المهمة</p>
       <p className="Missions-txt">برؤية تركز على العميل، هدفنا هو إنشاء تجربة تسوق شاملة ومُرضية. خلال هذه الرحلة، نحن ملتزمون بتقديم مجموعة متنوعة من المنتجات والخدمات بمستويات مختلفة والتأكد من توصيلها بسهولة وسرعة لعملائنا.</p>
      </div>
      <div ref={VissionsRef} className="Vissions-section">
  <p className="s-sub-title">بيان الرؤية</p>
  <div className="Vissions-content">
    <p className="Vissions-txt top-item">
    تعتمد رؤية "تجارة الراهر الإيراني" على ثلاثة أركان رئيسية.
    </p>
    <div className="Vissions-row">
      <div className="Vissions-item">
        <p className="Vissions-title">:الأول</p>
        <p className="Vissions-txt">
        أن نصبح شركة قابضة رائدة مع شركات فرعية متنوعة في صناعات مختلفة، لتعزيز الاستدامة والابتكار بشكل أكبر.
        </p>
      </div>
      <div className="Vissions-item">
        <p className="Vissions-title">:الثاني</p>
        <p className="Vissions-txt">
        أن نصبح واحدة من الخيارات الخمسة الأولى في المجتمع من خلال تقديم منتجات وخدمات متنوعة باستخدام التكنولوجيا الذكية.
        </p>
      </div>
      </div>
      <div className="Vissions-row2">
      <div className="Vissions-item">
        <p className="Vissions-title">الثالث</p>
        <p className="Vissions-txt">
        
        </p>
      </div>
    </div>
    
  </div>
</div>

      <div ref={ValuesRef} className="Values-section">
        <p className="s-sub-title">بیانیه ارزش ها</p>
        <div className="Values-row">
       <p className="Values-txt">
       <span className="Values-title">:الثقة ورضا أصحاب المصلحة</span> 
       < br/>
       .نحن ملتزمون ببناء الثقة وضمان الرضا بين جميع أصحاب المصلحة، سواء كانوا عملاءنا الكرام أو شركاءنا التجاريين المحترمين.
       </p>
       <p className="Values-txt">
       <span className="Values-title">:بيئة عائلية ودية داخل المنظمة</span>
       < br/>
       .نحن نرعى بيئة عمل تتصرف كعائلة داعمة وتلتزم بالنمو الشامل للموظفين
       </p>
       <p className="Values-txt">
       <span className="Values-title">:التطوير الشخصي</span> 
       < br/>
       .نحن ملتزمون بتطوير كل فرد داخل منظمتنا. نوفر الفرص والموارد اللازمة للتطوير الشخصي مثل تدريب المهارات والإرشاد وثقافة تشجع على التعلم المستمر والتطوير الذاتي
       </p>
         <p className="Values-txt">
         <span className="Values-title">:التطوير التنظيمي</span>
       < br/>
       كمنظمة تعلم، نحن ملتزمون بالتحسين المستمر والنمو. نستثمر في العمليات والأنظمة والاستراتيجيات التي تعزز التطوير التنظيمي وتساعدنا على تنفيذ التغييرات المبتكرة بمرونة
       </p>
       </div>
      </div>

      <div ref={prsons} className="about-page-ab">
      <div className="container123">
        <p className="s-sub-title">مجلس الإدارة</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
  className="cards-container-sb"
>
  {characters.map((characters, index) => (
    <SwiperSlide key={index}>
      <div className="card-ab">
        <img src={characters.image} alt="Profile" className="profile-image-ab" />
        <h2 className="name-ab">{characters.name}</h2>
        <p className="description-ab">{characters.description}</p>
        <div className="social-icons-ab">
          <a href={characters.links.linkedin} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href={characters.links.twitter} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href={characters.links.instagram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={characters.links.telegram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href={characters.links.whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
      </div>
      </div>
      <div ref={contactRef} className="contact-section">
    <p className="s-sub-title">اتصل بنا</p>
    <div className="contact-content">
    <div className="contact-infoer">
  <div className="contact-info">
   
  أرات – طريق هاشمي رفسنجاني من الشرق إلى الغرب – قبل مدخل كردستان
    <FaMapMarkerAlt style={{ marginLeft: "14px", color: "#ff8c42"}} />
    <br />
    حي الفجر – فجر 12 – المبنى رقم 21 – الطابق 1
  </div>	
  <div className="contact-info">
    info@ptrahro.com
    <FaEnvelope style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
  <div className="contact-info">
    +982191004056
    <FaPhoneAlt style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
</div>
        <form className="contact-form">
           <div className="form-grid">
  <label>
    <div className='Requiop'>
    <span>الاسم الكامل</span><span className="required">*</span>
    </div>
    <input type="text" name="fullname" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>رقم الهاتف</span><span className="required">*</span>
    </div>
    <input type="tel" name="phone" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>البريد الإلكتروني</span>
  </div>
    <input type="email" name="email" />
  </label>
  <label>
    <span>الموضوع</span>
    <input type="text" name="subject" />
  </label>
</div>
<label>
<div className='Requiop'>
  <span>الرسالة</span><span className="required">*</span>
 </div>
  <textarea name="message" rows="5" required></textarea>
</label>
<button type="submit">إرسال الرسالة</button>
        </form>
    </div>
</div>

    </div>
  );
}

export default AboutPagear;