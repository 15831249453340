import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import Toast from './popup';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Webcam from 'react-webcam';
import './UploadVideo.css';
import Loading from '../Loading'; // کامپوننت لودینگ
import CryptoJS from 'crypto-js';
import { FaVideo, FaStop, FaRedo, FaCheck } from 'react-icons/fa'; // آیکون تیک برای ارسال
const UploadVideo = (props) => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage1, setToastMessage1] = useState('');
  const [toastMessage2, setToastMessage2] = useState('');
  const [username, setUsername] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [countdown, setCountdown] = useState(null); // تایمر شمارش معکوس
  const [recordingTime, setRecordingTime] = useState(0); // تایمر مدت زمان ضبط
  const [videoURL, setVideoURL] = useState('');
  const [Video, setVideo] = useState('');
  const [videoTaken, setVideoTaken] = useState(false); // کنترل وضعیت ویدیو
  const [showCamera, setShowCamera] = useState(false); // کنترل نمایش دوربین
  const [cameraStream, setCameraStream] = useState(null); // ذخیره جریان دوربین
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const recordingIntervalRef = useRef(null);
  const recordingTimeoutRef = useRef(null); // برای محدود کردن مدت ضبط
  const [loading, setLoading] = useState(true);
  const encrypt = (text, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
    const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
    return base64.replace(/\//g, '_').replace(/\./g, '-');
};
const secretKey = "ExaByte13830908"
const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);
const handleEdit = async (e) => {
  // کد تغییر اطلاعات
  const phone_number = phoner;
  const stage = "5";
  try {
      const response = await fetch('http://141.11.21.74:5000/api/edit-form', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ phone_number, stage })
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
      }

  } catch (error) {
      console.error('Error:', error);
      alert(`مشکلی پیش آمد: ${error.message}`);
  }
};
     const [checked, setchecked] = useState([]);
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    console.log("WTF")
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                            try {
                                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                                const responseBody = await response.text();
                                if (!response.ok) {
                                    const errorData = JSON.parse(responseBody);
                                    throw new Error(errorData.error);
                                }
                                const data1 = JSON.parse(responseBody);
                                const stage1 = data1.Stage;
                                if (stage1 === '7'){
                                setchecked(true)
                                navigate('/dashboard')
                                }
                            } catch (error) {

                            }
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
    }, []);
    useEffect(() => {
        const phonee = phoner;
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phonee}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (stage === '2') {
                    navigate('/second-page'); // انتقال به صفحه /
                } else if (stage === '3') {
                    navigate('/next-page'); // انتقال به صفحه /
                } else  if (stage === '5') {
                    navigate('/wait'); // انتقال به صفحه /
                } else if (stage === '6') {
                    navigate('/code');
                }  else if (stage === '7') {
                    navigate('/login');
                } 
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();
    }, [checked])
  const handleUpload = async () => {
    const formDataToSend = new FormData();
    const secretKey = 'ExaByte830908';
    const encryptedText = encrypt(phoner, secretKey);
    formDataToSend.append('phoneNumber', phoner);
    formDataToSend.append('encryptedText', encryptedText);

    if (Video) {
        formDataToSend.append('video', Video);

    try {
        const response = await fetch('http://141.11.21.74:5000/upload-video', {
            method: 'POST',
            body: formDataToSend
        });
        const result = await response.json();
        alert('success');
        navigate('/wait')
        handleEdit()
    } catch (err) {
        console.error(err);
    }
};
  };

  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: 'user'
  });

  useEffect(() => {
    const getUsername = () => {
      const username = Cookies.get('TokenN');
      setUsername(username);
      setLoading(false);
    };
    setTimeout(getUsername, 500);
  }, []);

  // استفاده از useEffect برای تنظیم جریان دوربین پس از نمایش وبکم
  useEffect(() => {
    if (showCamera && cameraStream && webcamRef.current) {
      // تنظیم منبع تصویر وبکم بعد از اینکه showCamera به true تغییر کرد و webcamRef آماده شد
      webcamRef.current.srcObject = cameraStream;
    }
  }, [showCamera, cameraStream]); // این effect فقط زمانی اجرا می‌شود که showCamera یا cameraStream تغییر کند

  const handleStartRecordingClick = async () => {
    try {
      if (!showCamera) {
        // درخواست دسترسی به دوربین
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoConstraints
        });

        // بررسی جریان دسترسی به دوربین
        console.log('Camera access granted, setting stream.');
        
        setCameraStream(stream); // ذخیره جریان دوربین در وضعیت
        setShowCamera(true); // نمایش دوربین
      } else {
        // شروع شمارش معکوس
        console.log('Starting countdown.');
        startCountdown();
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const startCountdown = () => {
    console.log('Countdown started.'); // اضافه کردن پیام برای بررسی
    setCountdown(3); // شروع شمارش معکوس از 3
    const countdownInterval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(countdownInterval);
          setCountdown(null); // پنهان کردن شمارش معکوس
          startRecording(); // شروع ضبط پس از اتمام شمارش معکوس
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startRecording = () => {
    setIsRecording(true);
    setRecordingTime(0); // تنظیم تایمر ضبط به 0
    recordedChunks.current = [];
  
    const stream = webcamRef.current.video.srcObject;
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'video/webm'
    });
    mediaRecorderRef.current = mediaRecorder;
  
    mediaRecorder.addEventListener('dataavailable', (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    });
  
    mediaRecorder.addEventListener('stop', () => {
      const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);
      setVideo(blob);
      setVideoURL(url);
      setVideoTaken(true); // ویدیو ضبط شد
    });
  
    mediaRecorder.start();
  
    // شروع شمارش تایمر ضبط
    if (!recordingIntervalRef.current) {  // اطمینان از اینکه یک تایمر وجود دارد
      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime < 30) {
            return prevTime + 1; // هر ثانیه زمان را افزایش می‌دهیم
          } else {
            stopRecording(); // ضبط پس از ۳۰ ثانیه متوقف می‌شود
            return prevTime;
          }
        });
      }, 1000);
    }
  
    // توقف ضبط پس از 30 ثانیه
    recordingTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 30000); // 30,000 میلی‌ثانیه معادل 30 ثانیه
  };
  
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current); // متوقف کردن تایمر ضبط
      recordingIntervalRef.current = null; // پاک کردن مقدار تا دوباره اجرا نشود
    }
  
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current); // لغو تایمر 30 ثانیه‌ای
    }
  
    setIsRecording(false);
    setShowCamera(false); // پنهان کردن دوربین پس از ضبط
    setVideoTaken(true); // ویدیو گرفته شد
  };
  

  const handleTakeAgain = () => {
    setVideoURL(''); // حذف پیش‌نمایش ویدیو
    setVideoTaken(false); // تنظیم مجدد وضعیت ویدیو
    setShowCamera(true); // نمایش دوباره دوربین
  };

  // تبدیل ثانیه‌ها به قالب mm:ss برای تایمر
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  if (loading) {
    return <Loading />; // نمایش لودینگ تا زمانی که داده‌ها بارگذاری شوند
  }
  return (
    <div className="upload-video">
      <Header userName={username} activeTab="احراز هویت" />
      <main className="content4">
        <div className="camera-container">
          <h1 className="title-text">{username} عزیز، در این مرحله باید یک ویدیو از خودتون بگیرید</h1>
          <p className="highlight-text">لطفا حجاب اسلامی را رعایت فرمایید</p>
          <p className="instructions-text">
            بعد از کلیک روی شروع فیلمبرداری، متن قرار گرفته در بالای تصویر را با صدای واضح و رسا بخوانید
          </p>

          {showCamera && !videoURL && (
  <>
    <div className="video-container">
      <Webcam
        audio={false}
        ref={webcamRef}
        className="video-display"
      />
      {countdown !== null && <div className="countdown">{countdown}</div>}

      {/* نمایش متن و قاب راهنمای صورت حتی در هنگام شمارش معکوس */}
      <div className="overlay-text">
        <p>اینجانب {username}، صحت تمام اطلاعات وارد شده را تأیید میکنم</p>
      </div>
      <div className="face-guide"></div>

      {isRecording && (
        <div className="recording-status">
          <div className="blinking-dot"></div>
          <div className="timer">{formatTime(recordingTime)}</div>
        </div>
      )}
    </div>
  </>
)}


          {videoURL && !isRecording && (
            <div className="recorded-video">
              <h2 className="subtitle-text">:ویدیو گرفته شده</h2>
              <video src={videoURL} controls className="video-display" />
            </div>
          )}

          <div className="controls">
            {!isRecording && !videoTaken && countdown === null && (
              <button onClick={handleStartRecordingClick} className="start-button action-button">
                <FaVideo className="icon" /> شروع فیلم برداری
              </button>
            )}
            {!isRecording && videoTaken && (
              <button onClick={handleUpload} className="upload-button action-button">
                <FaCheck className="icon" /> ارسال
              </button>
            )}
            {!isRecording && videoTaken && (
              <button onClick={handleTakeAgain} className="redo-button action-button">
                <FaRedo className="icon" /> فیلم برداری مجدد
              </button>
            )}
            {isRecording && (
              <button onClick={stopRecording} className="stop-button action-button">
                <FaStop className="icon" /> پایان فیلم برداری
              </button>
            )}
          </div>
        </div>
      </main>
      <Footer />
      {showToast && (
        <Toast
          message1={toastMessage1}
          message2={toastMessage2}
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};


export default UploadVideo;
