import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './AboutPage.css';
import { Autoplay } from 'swiper/modules';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
const partners = [
  {
    image: "/images/post-s.png",
    link: "https://www.post.ir/"
  },
  {
    image: "/images/seda-va-sima.png",
    link: "https://www.pririb.ir"
  },
  {
    image: "/images/Mli-Bank.png",
    link: "https://www.bmi.ir/"
  },
  {
    image: "/images/Esteghlal Malasani.png",
    link: "https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87_%D9%81%D9%88%D8%AA%D8%A8%D8%A7%D9%84_%D8%A7%D8%B3%D8%AA%D9%82%D9%84%D8%A7%D9%84_%D9%85%D9%84%D8%A7%D8%AB%D8%A7%D9%86%DB%8C"
  },
  {
    image: "/images/Nedex.png",
    link: "https://nedex.ir/"
  },
  {
    image: "/images/Ariyana.png",
    link: ""
  }
];
const company = [
  {
    image: "/images/Bim.png",
    name: "Bimcheh",
    position: "",
    description: "Considering the importance of this document, Bimcheh, as a brand, is regarded as one of the leading players in this industry, aiming to enhance user satisfaction by facilitating services through digital platforms.",
    links: {
      website: "http://banian-vp.com",
    }
  },
  {
    image: "/images/Sav.png",
    name: "Sav",
    position: "",
    description: "Given the importance of customer loyalty and the highly competitive environment in most industries, establishing a customer club is inevitable in the service sector. Patra Rahro Iranian Trading has founded a subsidiary to provide suitable facilities for all its customers to benefit from services outside the Patra Rahro value chain.",
    links: {
      website: "https://www.iransavclub.com",
    }
  },
  {
    image: "/images/VarmiHome.png",
    name: "Varmi",
    position: "",
    description: "Varmi has made every effort to produce its products with the highest quality and modern design. Varmi has been active in the kitchenware production industry for several years and is looking to expand its product range.",
    links: {
      website: "https://varmihome.ir",
    }
  },
  {
    image: "/images/CityKitchen.png",
    name: "City Kitchen",
    position: "",
    description: "This company operates in the field of telemarketing, with more than 400 telephone sales representatives, selling household and personal care products.",
    links: {
      website: "https://shahreashpazkhaneh.ir",
    }
  },
  {
    image: "/images/LakPosht.png",
    name: "LakPosht",
    position: "",
    description: "With representatives in over 170 cities, LakPosht distributes goods and products with the help of about 300 couriers and collaborates with other distribution companies and the Islamic Republic of Iran Post Company.",
    links: {
      website: "lakpost.ir",
    }
  },
  {
    image: "/images/PatraTrade.png",
    name: "Patra Trade",
    position: "",
    description: "As a newly established company, this entity is responsible for the holding’s commercial and international activities.",
    links: {
      website: "https://patratrade.com/",
    }
  }
];


const characters = [
  {
    image: "/images/Parkhande.jpg",
    name: "Taha ParKhande",
    position: "CEO",
    description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Payam Nankali",
    position: "عضو هیئت مدیره",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Dr Rad Saeed",
    position: "عضو هیئت مدیره",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Dr Ebrahimi",
    position: "وکیل",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Sobhan Laleh",
    position: " ",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  // بقیه داده‌های کاراکترها
];
function Sidebar({ activeTab, onTabClick }) {
  const tabs = ["Our Story", "Sub Companys", "Commercial Partners", "Missions", "Vissions", "Values", "Board of Directors", "Contact Us"];
  return (
    <div className="sidebar-ab">
      <div
        className="progress-line-ab"
        style={{ height: `${((activeTab + 1) / tabs.length) * 100}%` }}
      ></div>
      <div className="tabs-ab-en">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-ab ${index === activeTab ? "active-ab" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

function AboutPageen() {
  const [activeTab, setActiveTab] = useState(0);
  const brandStoryRef = useRef(null);
  const subCompanysRef = useRef(null);
  const partnersRef = useRef(null); // مرجع برای بخش شرکای تجاری
  const boardRef = useRef(null);
  const MissionsRef = useRef(null);
  const VissionsRef = useRef(null);
  const ValuesRef = useRef(null);
  const prsons = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
      const handleScroll = () => {
        const sections = [
          brandStoryRef.current,
          subCompanysRef.current,
          partnersRef.current,
          boardRef.current,
          MissionsRef.current,
          VissionsRef.current,
          ValuesRef.current,
          prsons.current,
          contactRef.current,
        ];
    
        // فیلتر کردن بخش‌هایی که مقداردهی نشده‌اند
        const sectionOffsets = sections
          .filter(section => section) // حذف بخش‌های null
          .map(section => section.getBoundingClientRect().top);
    
        const activeIndex = sectionOffsets.findIndex(
          offset => offset >= 0 && offset < window.innerHeight / 2
        );
    
        if (activeIndex !== -1) {
          setActiveTab(activeIndex);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTabClick = (index) => {
      const refs = [brandStoryRef, subCompanysRef, partnersRef, MissionsRef, VissionsRef, ValuesRef, prsons, contactRef];

      
      // بررسی وجود ref قبل از scrollIntoView
      if (refs[index] && refs[index].current) {
        refs[index].current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Ref at index ${index} is not defined.`);
      }
    };
  return (
    <div className="All">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />

<div ref={brandStoryRef} className="brand-story-en">
  
        <p className="s-sub-title">Our Story</p>
        <div className="brand-story-item">
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
          <p className="brand-story-text">
          This company started its activities in 2014 in TV shopping, continuing by selling various products in categories such as clothing, sports equipment, cosmetics, and household appliances.
            <br /><br />
            In 2020, under the brand name Patra Rahro Iranian Trading, it entered the field of production, taking another step toward completing its value chain in producing Teflon cookware and home appliances.
          </p>
        </div>

        <div className="brand-story-item">
          <p className="brand-story-text">
          In continuation of its journey and in line with improving quality and services, it decided to establish its logistics network and provided its distribution platform, covering over 50% of the country’s geography and over 70% of the population. Currently, by establishing a customer club, it has taken another step toward customer loyalty and expanded its online and digital services. It has also become one of the prominent players in the industry by offering insurance services.
          </p>
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
        </div>
      </div>

 
      <div ref={subCompanysRef} className="sub-companys">
      <div className="container123">
        <p className="s-sub-title">Subsidiaries</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
          className="cards-container-sb"
        >
          {company.map((company, index) => (
            <SwiperSlide key={index}>
                <div className="card-sb">
    <img src={company.image} alt="Profile" className="profile-image-sb" />
    <h2 className="name-sb">{company.name}</h2>
    <p className="description-sb-en">{company.description}</p>
    <div className="website-button">
        <a href={company.links.website} target="_blank" rel="noopener noreferrer" className="site-link">
            <button className="site-button">
                <i className="fas fa-globe site-icon"></i>
            </button>
        </a>
    </div>
</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
      <div ref={partnersRef} className="partners-section">
        <p className="s-sub-title">Commercial Partners</p>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={partner.image} alt={`Partner ${index + 1}`} className="partner-logo" />
            </a>
          ))}
        </div>
      </div>
      <div ref={MissionsRef} className="Missions-section">
        <p className="s-sub-title">Mission's</p>
       <p className="Missions-txt-en">With a customer-focused vision, our goal is to create a comprehensive and satisfying shopping experience. Throughout this journey, we are committed to offering a range of products and services in different classes and ensuring easy and fast delivery to our customers.</p>
      </div>
      <div ref={VissionsRef} className="Vissions-section">
  <p className="s-sub-title">Vission's</p>
  <div className="Vissions-content-en">
    <p className="Vissions-txt top-item-en">
    Patra Rahro Iranian Trading's vision is based on three main pillars
    </p>
    <div className="Vissions-row-en">
      <div className="Vissions-item-en">
        <p className="Vissions-title">First:</p>
        <p className="Vissions-txt-en">
        To become a leading holding company with subsidiaries composed of diversified companies in various industries that can further strengthen sustainability and innovation.
        </p>
      </div>
      <div className="Vissions-item-en">
        <p className="Vissions-title-en">Second:</p>
        <p className="Vissions-txt-en">
        To become one of the top 5 choices in the community by offering products and services in different classes through infrastructure development and smart technology utilization.
        </p>
      </div>
      </div>
      <div className="Vissions-row2-en">
      <div className="Vissions-item-en">
        <p className="Vissions-title">thirst:</p>
        <p className="Vissions-txt-en">
        
        </p>
      </div>
    </div>
    
  </div>
</div>

      <div ref={ValuesRef} className="Values-section">
        <p className="s-sub-title">Values</p>
        <div className="Values-row">
       <p className="Values-txt-en">
       <span className="Values-title">Trust and Stakeholder Satisfaction:</span> 
       < br/>
       Trust and Stakeholder Satisfaction: We are committed to building trust and ensuring satisfaction among all our stakeholders, whether they are our valued customers or our respected business partners.
       </p>
       <p className="Values-txt-en">
       <span className="Values-title">Family and Friendly Atmosphere in the Organization:</span>
       < br/>
       Family and Friendly Atmosphere in the Organization: We nurture a work environment that acts like a supportive family and is dedicated to the comprehensive growth of employees.
       </p>
       <p className="Values-txt-en">
       <span className="Values-title">Personal Development:</span> 
       < br/>
       Personal Development: We are committed to the growth of each individual within our organization. We provide opportunities and resources for personal development, including skill training, mentoring, and a culture that promotes continuous learning and self-improvement.
       </p>
         <p className="Values-txt-en">
         <span className="Values-title">Organizational Development:</span>
       < br/>
       Organizational Development: Our organization, as a learning organization, is committed to continuous improvement and growth. We invest in processes, systems, and strategies that foster organizational development and promote agility, enabling us to implement innovative changes with flexibility.
       </p>
       </div>
      </div>

      <div ref={prsons} className="about-page-ab">
      <div className="container123">
        <p className="s-sub-title">Board of Directors</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
  className="cards-container-sb"
>
  {characters.map((characters, index) => (
    <SwiperSlide key={index}>
      <div className="card-ab">
        <img src={characters.image} alt="Profile" className="profile-image-ab" />
        <h2 className="name-ab">{characters.name}</h2>
        <p className="description-ab-en">{characters.description}</p>
        <div className="social-icons-ab">
          <a href={characters.links.linkedin} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href={characters.links.twitter} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href={characters.links.instagram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={characters.links.telegram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href={characters.links.whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
      </div>
      </div>
      <div ref={contactRef} className="contact-section-en">
    <p className="s-sub-title-en">Contact Us</p>
    <div className="contact-content-en">
    <div className="contact-infoer">
  <div className="contact-info-en">
  <FaMapMarkerAlt style={{ marginRight: "14px", color: "#ff8c42"}} />   
  Ararat – Hashemi Rafsanjani Highway, East to West – Before Kurdistan
    <br />
    Entrance. Fajr Town – Fajr 12 – No. 21 – Floor 1
    
  </div>	
  <div className="contact-info-en">
  <FaEnvelope style={{ marginRight: "14px", color: "#ff8c42" }} />
    info@ptrahro.com

  </div>	
  <div className="contact-info-en">
  <FaPhoneAlt style={{ marginRight: "14px", color: "#ff8c42" }} />
    +982191004056

  </div>	
</div>
        <form className="contact-form-en">
           <div className="form-grid">
  <label>
    <div className='Requiop'>
    <span>Name & LastName</span><span className="required">*</span>
    </div>
    <input type="text" name="fullname" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>Phone Number</span><span className="required">*</span>
    </div>
    <input type="tel" name="phone" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>Email</span>
  </div>
    <input type="email" name="email" />
  </label>
  <label>
    <span>Subject</span>
    <input type="text" name="subject" />
  </label>
</div>
<label>
<div className='Requiop'>
  <span>Message</span><span className="required">*</span>
 </div>
  <textarea name="message" rows="5" required></textarea>
</label>
<button type="submit">Send</button>
        </form>
    </div>
</div>

    </div>
  );
}

export default AboutPageen;