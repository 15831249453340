import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './AboutPage.css';
import { Autoplay } from 'swiper/modules';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaGlobe } from "react-icons/fa"
const partners = [
  {
    image: "/images/post-s.png",
    link: "https://www.post.ir/"
  },
  {
    image: "/images/seda-va-sima.png",
    link: "https://www.pririb.ir"
  },
  {
    image: "/images/Mli-Bank.png",
    link: "https://www.bmi.ir/"
  },
  {
    image: "/images/Esteghlal Malasani.png",
    link: "https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87_%D9%81%D9%88%D8%AA%D8%A8%D8%A7%D9%84_%D8%A7%D8%B3%D8%AA%D9%82%D9%84%D8%A7%D9%84_%D9%85%D9%84%D8%A7%D8%AB%D8%A7%D9%86%DB%8C"
  },
  {
    image: "/images/Nedex.png",
    link: "https://nedex.ir/"
  },
  {
    image: "/images/Ariyana.png",
    link: ""
  }
];
const company = [
  {
    image: "/images/Bim.png",
    name: "بیمچه",
    position: "",
    description: "با توجه به اهمیت این سند این شرکت با نام تجاری بیمچه یکی از برترین بازیگران این صنعت به شمار می‌آید که با تسهیل خدمات از طریق فضای دیجیتال سعی بر افزایش مطلوبیت استفاده کاربران خود دارد ",
    links: {
      website: "http://banian-vp.com",
    }
  },
  {
    image: "/images/Sav.png",
    name: "ساو",
    position: "",
    description: "امروزه با توجه به اهمیت وفادارسازی مشتریان و فضای رقابتی بسیار شدید در اکثر صنایع می‌توان گفت ایجاد باشگاه مشتریان امری اجتناب ناپذیر در حوزه خدمات به شمار می‌رودشرکت پایه تجارت رهرو ایرانیان با تاسیس شرکت زیر مجموعه امکان‌های بسیار مناسب برای تمامی مشتریان خود فراهم نموده تا بتوانند از تمامی خدمات موجود در خارج از زنجیره ارزش پایه تجارت نیز نهایت بهره را ببرند ",
    links: {
      website: "https://www.iransavclub.com",
    }
  },
  {
    image: "/images/VarmiHome.png",
    name: "وارمی",
    position: "",
    description: "شرکت وارمی تمام تلاش خود را کرده‌ است تا محصولات خود را با بالاترین کیفیت و طراحی مدرن تولید کند. وارمی چند سالی است که در زمینه تولید لوازم آشپزخانه فعالیت دارد و در پی گسترش تنوع محصولات خود است.",
    links: {
      website: "https://varmihome.ir",
    }
  },
  {
    image: "/images/CityKitchen.png",
    name: "شهر آشپزخانه",
    position: "",
    description: "این شرکت در حوزه فروش تلفنی، با بیش از ۴۰۰ فروشنده تلفنی مشغول به فعالیت است و لوازم خانگی و آرایش بهداشتی به فروش می‌رسانند.",
    links: {
      website: "https://shahreashpazkhaneh.ir",
    }
  },
  {
    image: "/images/LakPosht.png",
    name: "لاكپشت",
    position: "",
    description: "این شرکت در بیش از ۱۷۰ شهر دارای نمایندگی است و از این طریق توزیع کالا و محصولات را انجام می‌دهد. لاکپشت این مهم را توسط حدود ۳۰۰ نفر از سفیران خود، تحت پوشش قرار داده و همچنین با سایر شرکت‌های پیمانکار توزیع و شرکت پست جمهوری اسلامی ایران همکاری دارد.",
    links: {
      website: "lakpost.ir",
    }
  },
  {
    image: "/images/PatraTrade.png",
    name: "پاترا تريد",
    position: "",
    description: "این مجموعه به عنوان یک شرکت تازه تاسیس، فعالیت‌های بازرگانی و بین المللی هولدینگ را بر عهده دارد.",
    links: {
      website: "https://patratrade.com/",
    }
  },
 
  // بقیه داده‌های شرکت‌ها
];

const characters = [
  {
    image: "/images/Parkhande.jpg",
    name: "طاها پرخنده",
    position: "مدیر عامل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "پیام نانکلی",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر راد سعید",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر ابراهیمی",
    position: "وکیل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "سبحان لاله",
    position: " ",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  // بقیه داده‌های کاراکترها
];
function Sidebar({ activeTab, onTabClick }) {
  const tabs = ["داستان ما", "زیرمجموعه ها", "شُرکای تجاری", "بیانیه ماموریت", "بیانیه چشم انداز", "بیانیه ارزش ها", "هیئت مدیره", "تماس با ما"];
  return (
    <div className="sidebar-ab">
      <div
        className="progress-line-ab"
        style={{ height: `${((activeTab + 1) / tabs.length) * 100}%` }}
      ></div>
      <div className="tabs-ab">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-ab ${index === activeTab ? "active-ab" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

function AboutPage() {
  const [activeTab, setActiveTab] = useState(0);
  const brandStoryRef = useRef(null);
  const subCompanysRef = useRef(null);
  const partnersRef = useRef(null); // مرجع برای بخش شرکای تجاری
  const boardRef = useRef(null);
  const MissionsRef = useRef(null);
  const VissionsRef = useRef(null);
  const ValuesRef = useRef(null);
  const prsons = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
      const handleScroll = () => {
        const sections = [
          brandStoryRef.current,
          subCompanysRef.current,
          partnersRef.current,
          boardRef.current,
          MissionsRef.current,
          VissionsRef.current,
          ValuesRef.current,
          prsons.current,
          contactRef.current,
        ];
    
        // فیلتر کردن بخش‌هایی که مقداردهی نشده‌اند
        const sectionOffsets = sections
          .filter(section => section) // حذف بخش‌های null
          .map(section => section.getBoundingClientRect().top);
    
        const activeIndex = sectionOffsets.findIndex(
          offset => offset >= 0 && offset < window.innerHeight / 2
        );
    
        if (activeIndex !== -1) {
          setActiveTab(activeIndex);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTabClick = (index) => {
      const refs = [brandStoryRef, subCompanysRef, partnersRef, MissionsRef, VissionsRef, ValuesRef, prsons, contactRef];

      
      // بررسی وجود ref قبل از scrollIntoView
      if (refs[index] && refs[index].current) {
        refs[index].current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Ref at index ${index} is not defined.`);
      }
    };
  return (
    <div className="All">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />

<div ref={brandStoryRef} className="brand-story">
  
        <p className="s-sub-title">داستان ما</p>
        <div className="brand-story-item">
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
          <p className="brand-story-text">
            این شرکت از سال ۱۳۹۳ در زمینه تی‌وی شاپینگ شروع به فعالیت کرده با فروش محصولات مختلف در دسته‌های مختلف از قبیل پوشاک لوازم ورزشی محصولات آرایشی و بهداشتی و لوازم خانگی به مسیر خود ادامه داده
            <br /><br />
            از سال ۱۳۹۹ با نام تجاری پایه تجارت رهرو ایرانیان پا به عرصه تولید گذاشته و در زمینه تولید ظروف تفلون و لوازم برقی خانگی قدم دیگری در راستای تکمیل زنجیره ارزش خود برداشته.
          </p>
        </div>

        <div className="brand-story-item">
          <p className="brand-story-text">
            در ادامه مسیر و در راستای بهبود کیفیت و خدمات خود، تصمیم به ایجاد شبکه لجستیکی خود گرفته و بستر توزیع کالای خود را با پوشش بیش از ۵۰ درصد جغرافیای کشور و بالغ بر ۷۰ درصد جمعیت را فراهم نمود. در حال حاضر با ایجاد باشگاه مشتریان قدمی دیگر در راستای وفادارسازی مشتریان خود و فعالیت بیشتر در حوزه آنلاین و خدمات دیجیتال برداشته و همچنین با ارائه خدمات بیمه یکی از بازیگران مطرح این صنعت به حساب می‌آید.
          </p>
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
        </div>
      </div>

 
      <div ref={subCompanysRef} className="sub-companys">
      <div className="container123">
        <p className="s-sub-title">زیر مجموعه ها</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    3000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    2000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
          className="cards-container-sb"
        >
          {company.map((company, index) => (
            <SwiperSlide key={index}>
          <div className="card-sb">
    <img src={company.image} alt="Profile" className="profile-image-sb" />
    <h2 className="name-sb">{company.name}</h2>
    <p className="description-sb">{company.description}</p>
    <div className="website-button">
        <a href={company.links.website} target="_blank" rel="noopener noreferrer" className="site-link">
            <button className="site-button">
                <i className="fas fa-globe site-icon"></i>
            </button>
        </a>
    </div>
</div>


            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
      <div ref={partnersRef} className="partners-section">
        <p className="s-sub-title">شرکای تجاری</p>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={partner.image} alt={`Partner ${index + 1}`} className="partner-logo" />
            </a>
          ))}
        </div>
      </div>
      <div ref={MissionsRef} className="Missions-section">
        <p className="s-sub-title">بیانیه ماموریت ها</p>
       <p className="Missions-txt">با دیدگاه متمرکز بر مشتری هدف ما ایجاد یک تجربه خرید جامع و رضایت بخش است در طول این سفر ما متعهد به ارائه مجموعه‌ای از محصولات و خدمات در کلاس‌های مختلف و حصول اطمینان از تحویل آسان و سریع به مشتریانمان هستیم</p>
      </div>
      <div ref={VissionsRef} className="Vissions-section">
  <p className="s-sub-title">بیانیه چشم انداز</p>
  <div className="Vissions-content">
    <p className="Vissions-txt top-item">
      چشم انداز پایه تجارت رهرو ایرانیان بر سه رکن اصلی متکی است
    </p>
    <div className="Vissions-row">
      <div className="Vissions-item">
        <p className="Vissions-title">:اول</p>
        <p className="Vissions-txt">
          تبدیل شدن به یک شرکت هلدینگ پیشرو با زیر مجموعه متشکل از شرکت‌های متنوع در صنایع مختلف که بتواند هرچه بیشتر پایداری و نوآوری را تقویت کند
        </p>
      </div>
      <div className="Vissions-item">
        <p className="Vissions-title">:دوم</p>
        <p className="Vissions-txt">
          تبدیل شدن به یکی از ۵ انتخاب برتر جامعه با ارائه محصولات و خدمات در کلاس‌های مختلف از طریق توسعه زیرساخت و استفاده از فناوری هوشمند
        </p>
      </div>
      </div>
      <div className="Vissions-row2">
      <div className="Vissions-item">
        <p className="Vissions-title">:سوم</p>
        <p className="Vissions-txt">
        
        </p>
      </div>
    </div>
    
  </div>
</div>

      <div ref={ValuesRef} className="Values-section">
        <p className="s-sub-title">بیانیه ارزش ها</p>
        <div className="Values-row">
       <p className="Values-txt">
       <span className="Values-title">اعتماد و رضایت ذینفعان:</span> 
       < br/>
       ما متعهد به ایجاد اعتماد و اطمینان از رضایت در بین همه ذینفعان خود هستیم خواه آنها مشتریان ارزشمند ما باشند یا شرکای تجاری محترم ما 
       </p>
       <p className="Values-txt">
       <span className="Values-title">جو خانوادگی و دوستانه در سازمان :</span>
       < br/>
       ما محیط کاری را پرورش می‌دهیم که مانند یک خانواده حامی عمل می‌کند و متعهد به رشد همه جانبه کارکنان است 
       </p>
       <p className="Values-txt">
       <span className="Values-title">توسعه فردی :</span> 
       < br/>
       ما متعهد به رشد هر فرد در سازمان خود هستیم ما فرصت‌ها و منابعی را برای توسعه شخصی افراد خود فراهم می‌کنیم مانند: آموزش مهارت ها ، منتورینگ و فرهنگی که یادگیری مستمر و خودسازی را تشویق می‌کند 
       </p>
         <p className="Values-txt">
         <span className="Values-title">توسعه سازمانی :</span>
       < br/>
       سازمان ما به عنوان یک سازمان یادگیرنده به بهبود و رشد مستمر متعهد است ما روی فرایندها،سیستم‌ها و استراتژی‌هایی سرمایه‌گذاری می‌کنیم که توسعه سازمانی را تقویت می‌کنند. با توسعه سازمانی را از طریق چابکی پیش می‌بریم ؛ چابکی ما را قادر می‌سازد تا با انعطاف پذیری تغییرات نوآورانه را در سازمان پیاده‌سازی کنیم
       </p>
       </div>
      </div>

      <div ref={prsons} className="about-page-ab">
      <div className="container123">
        <p className="s-sub-title">هیئت مدیره</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
  className="cards-container-sb"
>
  {characters.map((characters, index) => (
    <SwiperSlide key={index}>
      <div className="card-ab">
        <img src={characters.image} alt="Profile" className="profile-image-ab" />
        <h2 className="name-ab">{characters.name}</h2>
        <p className="description-ab">{characters.description}</p>
        <div className="social-icons-ab">
          <a href={characters.links.linkedin} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href={characters.links.twitter} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href={characters.links.instagram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={characters.links.telegram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href={characters.links.whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
      </div>
      </div>
      <div ref={contactRef} className="contact-section">
    <p className="s-sub-title">تماس با ما</p>
    <div className="contact-content">
    <div className="contact-infoer">
  <div className="contact-info">
   
    آرارات – اتوبان هاشمی رفسنجانی شرق به غرب – نرسيده به
    <FaMapMarkerAlt style={{ marginLeft: "14px", color: "#ff8c42"}} />
    <br />
    ورودي كردستان – شهرك فجر – فجر ۱۲ – پلاک ۲۱ – ط ۱
  </div>	
  <div className="contact-info">
    info@ptrahro.com
    <FaEnvelope style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
  <div className="contact-info">
    +982191004056
    <FaPhoneAlt style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
</div>
        <form className="contact-form">
           <div className="form-grid">
  <label>
    <div className='Requiop'>
    <span>نام و نام خانوادگی</span><span className="required">*</span>
    </div>
    <input type="text" name="fullname" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>شماره تلفن</span><span className="required">*</span>
    </div>
    <input type="tel" name="phone" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>ایمیل</span>
  </div>
    <input type="email" name="email" />
  </label>
  <label>
    <span>موضوع</span>
    <input type="text" name="subject" />
  </label>
</div>
<label>
<div className='Requiop'>
  <span>پیام</span><span className="required">*</span>
 </div>
  <textarea name="message" rows="5" required></textarea>
</label>
<button type="submit">ارسال پیام</button>
        </form>
    </div>
</div>

    </div>
  );
}

export default AboutPage;