// cityCodes.js
export const cityCodes = {
    "آذرشهر": "041",
    "آمل": "011",
    "آباده": "071",
    "آبیک": "028",
    "آبدانان": "084",
    "آمل": "011",
    "آزادشهر": "017",
    "آستارا": "013",
    "آستانه‌اشرفیه": "013",
    "آشتیان": "086",
    "آق‌قلا": "017",
    "آمل": "011",
    "ابهر": "024",
    "اراک": "086",
    "اردبیل": "045",
    "اردستان": "031",
    "ارومیه": "044",
    "اریاشهر": "086",
    "ارژن": "071",
    "اهر": "041",
    "اسکو": "041",
    "اصفهان": "031",
    "اقبالیه": "028",
    "البرز": "026",
    "الیگودرز": "066",
    "امام‌شهر": "086",
    "اهواز": "061",
    "ایلام": "084",
    "ایوانکی": "023",
    "آمل": "011",
    "بابل": "011",
    "بابلسر": "011",
    "باجگیران": "051",
    "باغ بهادران": "031",
    "بافت": "034",
    "بجنورد": "058",
    "برازجان": "077",
    "بردسیر": "034",
    "بروجن": "038",
    "بروجرد": "066",
    "بستان‌آباد": "041",
    "بم": "034",
    "بندرگز": "017",
    "بندرانزلی": "013",
    "بندرعباس": "076",
    "بندرماهشهر": "061",
    "بوانات": "071",
    "بوکان": "044",
    "بوشهر": "077",
    "بومهن": "021",
    "بهرمان": "034",
    "بیرجند": "056",
    "بیستون": "083",
    "بیدستان": "028",
    "تبریز": "041",
    "تفرش": "086",
    "تکاب": "044",
    "تنکابن": "011",
    "تهران": "021",
    "تیران": "031",
    "جلفا": "041",
    "جم": "077",
    "جوین": "058",
    "چابهار": "054",
    "چالوس": "011",
    "چمران": "061",
    "چمگردان": "031",
    "خاش": "054",
    "خرم‌آباد": "066",
    "خرمدره": "024",
    "خرمشهر": "061",
    "خلخال": "045",
    "خوانسار": "031",
    "داراب": "071",
    "دامغان": "023",
    "دلیجان": "086",
    "دماوند": "021",
    "دهدشت": "074",
    "دهلران": "084",
    "دورود": "066",
    "رشت": "013",
    "رامهرمز": "061",
    "رامیان": "017",
    "رفسنجان": "034",
    "رودسر": "013",
    "زرند": "034",
    "زنجان": "024",
    "زاهدان": "054",
    "زابل": "054",
    "زرقان": "071",
    "زرندیه": "086",
    "زنجان": "024",
    "ساوه": "086",
    "سبزوار": "051",
    "سراب": "041",
    "سراوان": "054",
    "سرباز": "054",
    "سرپل‌ذهاب": "083",
    "سردشت": "044",
    "سرخس": "051",
    "سریش‌آباد": "087",
    "سقز": "087",
    "سمنان": "023",
    "سنندج": "087",
    "سهند": "041",
    "شازند": "086",
    "شاهین‌شهر": "031",
    "شبستر": "041",
    "شهرضا": "031",
    "شهرکرد": "038",
    "شوش": "061",
    "شوشتر": "061",
    "شیراز": "071",
    "صحنه": "083",
    "قائم‌شهر": "011",
    "قصرشیرین": "083",
    "قم": "025",
    "قوچان": "051",
    "قزوین": "028",
    "قلعه‌حسن‌خان": "021",
    "کارگر": "061",
    "کازرون": "071",
    "کامیاران": "087",
    "کردکوی": "017",
    "کرج": "026",
    "کرمان": "034",
    "کرمانشاه": "083",
    "کنگان": "077",
    "کوهدشت": "066",
    "کیش": "076",
    "گتوند": "061",
    "گرگان": "017",
    "گرمسار": "023",
    "گیلان‌غرب": "083",
    "لار": "071",
    "لاریم": "011",
    "لامرد": "071",
    "لنجان": "031",
    "لواسان": "021",
    "ماهشهر": "061",
    "محمودآباد": "011",
    "مراغه": "041",
    "مرودشت": "071",
    "مشهد": "051",
    "مهاباد": "044",
    "میاندوآب": "044",
    "میناب": "076",
    "مینودشت": "017",
    "میانه": "041",
    "نجف‌آباد": "031",
    "نکا": "011",
    "نقده": "044",
    "نور": "011",
    "نظرآباد": "026",
    "نیشابور": "051",
    "یزد": "035",
    // ... بقیه شهرها
};
