import React, { useState, useEffect, useRef } from 'react';
import './LoginForm.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function LoginForm({ onLogin, stage }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loged, setloged] = useState('');
    const [stager, setstager] = useState('');
    const [otpArray, setOtpArray] = useState(new Array(6).fill('')); // ایجاد آرایه‌ای برای ۶ باکس
    const [cooldown, setCooldown] = useState(0); // مدیریت کول داون
    const [buttonText, setButtonText] = useState('دریافت کد'); // مدیریت متن دکمه
    const [errorIndex, setErrorIndex] = useState(null); // مدیریت خطا برای ورودی‌های کد
    const inputRefs = useRef([]); // ذخیره رفرنس برای هر ورودی
    const [phoneError, setPhoneError] = useState(false); // مدیریت خطای ورودی شماره تلفن
    const [phono, setphono] = useState(); //
    const [otopo, setotopo] = useState();
    const [checked, setchecked] = useState([]);
    const navigate = useNavigate();
    
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) { // اگر ورودی عدد است
            setPhoneNumber(value);
            setPhoneError(false); // حذف خطا
        } else {
            setPhoneError(true); // اعمال خطا
            setTimeout(() => setPhoneError(false), 2000); // حذف خطا بعد از ۲ ثانیه
        }
    };
    const secretKey = "ExaByte13830908"
const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);

  useEffect(() => {
    const CheckLogined = async () => {
        const ctoken = Cookies.get('token');
        try {
            const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone_number: phoner, token: ctoken }),
                credentials: 'include',  // اضافه کردن این خط برای ارسال کوکی‌ها
            });
            const data = await response.json();
            if (data.success) {
                setchecked(false)
                const code = Cookies.get('coder');
                if (code) {
                try {
                  const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({ phone_number: phoner, code: code }),
                  });
              
                  if (response1.ok) {
                    try {
                        const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        const responseBody = await response.text();
                        if (!response.ok) {
                            const errorData = JSON.parse(responseBody);
                            throw new Error(errorData.error);
                        }
                        const data1 = JSON.parse(responseBody);
                        const stage1 = data1.Stage;
                        if (stage1 === '7'){
                        setchecked(true)
                        navigate('/dashboard')
                        }
                    } catch (error) {

                    }
                  } else {
                  }
              } catch (error) {
                  console.error('Error:', error);
                  alert(`مشکلی پیش آمد: ${error.message}`);
              }
            }
            else {
            }
        }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    CheckLogined();
}, [phoner, Cookies.get('coder')]);
useEffect(() => {
    if (phoner) {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (!checked) {
                    if (stage === '2') {
                        navigate('/second-page');
                    } else if (stage === '3') {
                        navigate('/next-page');
                    }else if (stage === '4') {
                        navigate('/upload-video');
                    } else if (stage === '5') {
                        navigate('/wait');
                    } else if (stage === '6') {
                        navigate('/code');
                    } else if (stage === '7') {
                        navigate('/login');
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();
    }
}, [checked, phoner]);

    
    
    // انتقال به باکس بعدی در صورت وارد کردن عدد
    const handleOtpChange = (e, index) => {
        const value = e.target.value;

        if (/^[0-9]$/.test(value)) { // فقط اجازه ورود اعداد
            const newOtpArray = [...otpArray];
            newOtpArray[index] = value;
            setOtpArray(newOtpArray);
            setErrorIndex(null); // پاک کردن خطا

            if (index < 5) {
                inputRefs.current[index + 1].focus(); // انتقال به باکس بعدی
            }
        } else if (value === "") {
            const newOtpArray = [...otpArray];
            newOtpArray[index] = value;
            setOtpArray(newOtpArray);
        } else {
            // اضافه کردن کلاس خطا برای ۲ ثانیه
            setErrorIndex(index);
            setTimeout(() => setErrorIndex(null), 2000);
        }
    };

    const handleOtpRequest = async (e) => {
        e.preventDefault();
        if (cooldown > 0) return; // جلوگیری از ارسال در زمان کول داون

        try {
            const response = await fetch('http://141.11.21.74:5000/api/generate-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone_number: phoneNumber }),
            });
            const data = await response.json();

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            alert(`کد یکبار مصرف شما: ${data.otp}`);
            
            setButtonText('ارسال دوباره'); // تغییر متن دکمه به "ارسال دوباره"
            setCooldown(60); // تنظیم کول داون ۶۰ ثانیه‌ای

            // تایمر کول داون
            const cooldownInterval = setInterval(() => {
                setCooldown(prevCooldown => {
                    if (prevCooldown <= 1) {
                        clearInterval(cooldownInterval); // متوقف کردن تایمر
                        return 0;
                    }
                    return prevCooldown - 1;
                });
            }, 1000);

        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }
    };
    const handleTokenRequest = async (e) => {
        const otp = otpArray.join('');
        try {
            const response = await fetch('http://141.11.21.74:5000/api/generate-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone_number: phoneNumber, otp: otp }),
            });
            const data = await response.json();
            const token = data.token
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            } else {
                Cookies.set('token', token)
            }
        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        const otp = otpArray.join(''); // ترکیب ورودی‌های کد
        try {
            const response = await fetch('http://141.11.21.74:5000/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone_number: phoneNumber, otp: otp }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }
            const secretKey = "ExaByte13830908"
            const encrypt = (text, secretKey) => {
                const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
                const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
                return base64
            };
            const en1phone = encrypt(phoneNumber, secretKey);
            handleTokenRequest()
            alert('خوش آمدید به داشبورد!');
            onLogin(phoneNumber); // ارسال شماره تلفن به `App.js`
            Cookies.set('phone', en1phone);
            navigate('/second-page');
        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }
    };

    return (
        <div className="Login">
            <div className="wrapper">
                <div className="form-group">
                    <div className="phone-and-button">
                    <div className="input-field">
    <input
        type="text"
        placeholder="09123456789"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        className={phoneError ? 'error' : ''} // اعمال کلاس خطا
        required
    />
</div>

                        <button
                            type="submit"
                            className="button-small"
                            onClick={handleOtpRequest}
                            disabled={cooldown > 0} // غیرفعال کردن دکمه در زمان کول داون
                        >
                            {cooldown > 0 ? `ارسال دوباره (${cooldown})` : buttonText}
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <div className="otp-and-label">
                        <label className="otp-label">:کد دریافتی</label>
                        <div className="otp-inputs">
                            {otpArray.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleOtpChange(e, index)}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className={errorIndex === index ? 'error' : ''} // اعمال کلاس خطا در صورت ورود غیر عدد
                                />
                            ))}
                        </div>
                    </div>
                    <button className="full-width-button" onClick={handleLogin}>
                        ورود
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
