import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './AboutPage.css';
import { Autoplay } from 'swiper/modules';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
const partners = [
  {
    image: "/images/post-s.png",
    link: "https://www.post.ir/"
  },
  {
    image: "/images/seda-va-sima.png",
    link: "https://www.pririb.ir"
  },
  {
    image: "/images/Mli-Bank.png",
    link: "https://www.bmi.ir/"
  },
  {
    image: "/images/Esteghlal Malasani.png",
    link: "https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87_%D9%81%D9%88%D8%AA%D8%A8%D8%A7%D9%84_%D8%A7%D8%B3%D8%AA%D9%82%D9%84%D8%A7%D9%84_%D9%85%D9%84%D8%A7%D8%AB%D8%A7%D9%86%DB%8C"
  },
  {
    image: "/images/Nedex.png",
    link: "https://nedex.ir/"
  },
  {
    image: "/images/Ariyana.png",
    link: ""
  }
];
const company = [
  {
    image: "/images/Bim.png",
    name: "بیمچه",
    position: "",
    description: "بەهۆی گرنگی ئەم بەڵگەیەدا، بیمچه وەک مارکا چالاکە لەو بواری کارییەدا هەژمار دەکرێت کە ئەوەمان بەرامبەر بووە بە دابینکردنی خوایاندن بە شێوەی دیجیتاڵ.",
    links: {
      website: "http://banian-vp.com",
    }
  },
  {
    image: "/images/Sav.png",
    name: "ساو",
    position: "",
    description: "لەبەر گرنگی سەقامگیری کڕیار و جیاوازی زیادەی کۆمپیتیشنی بواری ئەم سەکۆرە، دروستکردنی یەک کلوپە کڕیاران پێویستە بۆ دابینکردنی خوایاندنە سەیرەکان. کۆمپانیای پاترا ڕەحڕۆی ئیرانی فۆند کردووە یەک تاقی بۆ پاراستنی کڕیارانەکانی ئەوەی.",
    links: {
      website: "https://www.iransavclub.com",
    }
  },
  {
    image: "/images/VarmiHome.png",
    name: "وارمی",
    position: "",
    description: "وارمی بەهەموو توانا کار دەکات بۆ بەرهەم هینانەوە بە باڵاترین کەیفیت و دیزاینی ئاستەنگە. وارمی چەند ساڵەیە چالاکە لە بەرهەم هینانەوەی خواردنی خانوو کە هەوڵی توندە بۆ فڕۆشتنی بەرهەمە نوێکان.",
    links: {
      website: "https://varmihome.ir",
    }
  },
  {
    image: "/images/CityKitchen.png",
    name: "شاری آشپازخانە",
    position: "",
    description: "ئەم کۆمپانیایە بە شێوەی فڕۆشی تەلەفونە، خزمەتی فڕۆشی خواردنی خانوو و خواردنی تایبەتی بە ٤٠٠ یەکسانەکانی فڕۆشی تەلەفون دەکات.",
    links: {
      website: "https://shahreashpazkhaneh.ir",
    }
  },
  {
    image: "/images/LakPosht.png",
    name: "لاکپۆشت",
    position: "",
    description: "لاکپۆشت لە زیاتر لە ١٧٠ شاردا کەسایەتی فڕۆشی هەیە، بە کەمەیەک کەسایەتی کات دەگەیەنێت. لاکپۆشت پەیوەندی دەکات بە کۆمپانیاکانی دەرکەوتن و کۆمپانیا پۆستی جومهەوریت.",
    links: {
      website: "lakpost.ir",
    }
  },
  {
    image: "/images/PatraTrade.png",
    name: "پاترا ترێد",
    position: "",
    description: "وەک یەک کۆمپانیای نوێ ڕەخنەکراوە، ئەم کورسیە لە ئەرکی کەرتەوەی وەزارەتی و نەتەوەیی هەیە بۆ ڕوونکردنەوە.",
    links: {
      website: "https://patratrade.com/",
    }
  }
];


const characters = [
  {
    image: "/images/Parkhande.jpg",
    name: "طاها پرخنده",
    position: "مدیر عامل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "پیام نانکلی",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر راد سعید",
    position: "عضو هیئت مدیره",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "دکتر ابراهیمی",
    position: "وکیل",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "سبحان لاله",
    position: " ",
    description: "توضیحات، توضیحات، توضیحات توضیحات، توضیحات، توضیحات...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  // بقیه داده‌های کاراکترها
];
function Sidebar({ activeTab, onTabClick }) {
  const tabs = ["چیرۆكی ئێمە", "بەشەکان", "هاوبەشە بازرگانیەکان", "بانگهێشتی ئەرک", "بینینی داهاتوو", "نرخییەکان", "ئەندامانی هەیەتی بەرەوپێش", "پەیوەندیدان بە ئێمە"];
  return (
    <div className="sidebar-ab">
      <div
        className="progress-line-ab"
        style={{ height: `${((activeTab + 1) / tabs.length) * 100}%` }}
      ></div>
      <div className="tabs-ab">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-ab ${index === activeTab ? "active-ab" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

function AboutPageku() {
  const [activeTab, setActiveTab] = useState(0);
  const brandStoryRef = useRef(null);
  const subCompanysRef = useRef(null);
  const partnersRef = useRef(null); // مرجع برای بخش شرکای تجاری
  const boardRef = useRef(null);
  const MissionsRef = useRef(null);
  const VissionsRef = useRef(null);
  const ValuesRef = useRef(null);
  const prsons = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
      const handleScroll = () => {
        const sections = [
          brandStoryRef.current,
          subCompanysRef.current,
          partnersRef.current,
          boardRef.current,
          MissionsRef.current,
          VissionsRef.current,
          ValuesRef.current,
          prsons.current,
          contactRef.current,
        ];
    
        // فیلتر کردن بخش‌هایی که مقداردهی نشده‌اند
        const sectionOffsets = sections
          .filter(section => section) // حذف بخش‌های null
          .map(section => section.getBoundingClientRect().top);
    
        const activeIndex = sectionOffsets.findIndex(
          offset => offset >= 0 && offset < window.innerHeight / 2
        );
    
        if (activeIndex !== -1) {
          setActiveTab(activeIndex);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTabClick = (index) => {
      const refs = [brandStoryRef, subCompanysRef, partnersRef, MissionsRef, VissionsRef, ValuesRef, prsons, contactRef];

      
      // بررسی وجود ref قبل از scrollIntoView
      if (refs[index] && refs[index].current) {
        refs[index].current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Ref at index ${index} is not defined.`);
      }
    };
  return (
    <div className="All">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />

<div ref={brandStoryRef} className="brand-story">
  
        <p className="s-sub-title">چیرۆكی ئێمە</p>
        <div className="brand-story-item">
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
          <p className="brand-story-text">
          ئەم کۆمپانیا لە ساڵی ١٣٩٣ بە پەیوەندی بە تیڤی شاپینگ دەستی کرد و بە فرۆشتنی مافیەکانی مێژووی کەیفی نەخۆشی، خانەبووک و چەند گۆڕۆپەی تری تەنها، بە ڕێڕەوی ئەم دووگمەگەیە دەکەوێت.
            <br /><br />
            لە ساڵی ١٣٩٩ بە ناوی نیشانی پەیوەندیدانی پاترا ڕاهرو ایرانیان، جیهانی خزمەتەکانی شەریک بەرزایی پێگەشتەوە بە پاراستنی تەرمی تەواوی کاریگەری بەرز.
          </p>
        </div>

        <div className="brand-story-item">
          <p className="brand-story-text">
            در ادامه مسیر و در راستای بهبود کیفیت و خدمات خود، تصمیم به ایجاد شبکه لجستیکی خود گرفته و بستر توزیع کالای خود را با پوشش بیش از ۵۰ درصد جغرافیای کشور و بالغ بر ۷۰ درصد جمعیت را فراهم نمود. در حال حاضر با ایجاد باشگاه مشتریان قدمی دیگر در راستای وفادارسازی مشتریان خود و فعالیت بیشتر در حوزه آنلاین و خدمات دیجیتال برداشته و همچنین با ارائه خدمات بیمه یکی از بازیگران مطرح این صنعت به حساب می‌آید.
          </p>
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
        </div>
      </div>

 
      <div ref={subCompanysRef} className="sub-companys">
      <div className="container123">
        <p className="s-sub-title">بەشەکان</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    3000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    2000: {
      slidesPerView: 2, // برای صفحه‌نمایش‌های بزرگ
    },
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
          className="cards-container-sb"
        >
          {company.map((company, index) => (
            <SwiperSlide key={index}>
              <div className="card-sb">
    <img src={company.image} alt="Profile" className="profile-image-sb" />
    <h2 className="name-sb">{company.name}</h2>
    <p className="description-sb">{company.description}</p>
    <div className="website-button">
        <a href={company.links.website} target="_blank" rel="noopener noreferrer" className="site-link">
            <button className="site-button">
                <i className="fas fa-globe site-icon"></i>
            </button>
        </a>
    </div>
</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
      <div ref={partnersRef} className="partners-section">
        <p className="s-sub-title">هاوبەشە بازرگانیەکان</p>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={partner.image} alt={`Partner ${index + 1}`} className="partner-logo" />
            </a>
          ))}
        </div>
      </div>
      <div ref={MissionsRef} className="Missions-section">
        <p className="s-sub-title">بانگهێشتی ئەرک</p>
       <p className="Missions-txt">.بە بەرژەوەندیی کڕیارەکانمان و ئەم بە شێوەیە بەرز بڕگەی کڕینێکی تەواو و خۆشحاڵیدانی ناردین، بۆ ئەو کاتە، ئەرکی ئێمە بە جێبەجێکردنی هەموو پێویستی و هاوكارییەکانمان تێچووە</p>
      </div>
      <div ref={VissionsRef} className="Vissions-section">
  <p className="s-sub-title">بینینی داهاتوو</p>
  <div className="Vissions-content">
    <p className="Vissions-txt top-item">
      چشم انداز پایه تجارت رهرو ایرانیان بر سه رکن اصلی متکی است
    </p>
    <div className="Vissions-row">
      <div className="Vissions-item">
        <p className="Vissions-title">:یەکەم</p>
        <p className="Vissions-txt">
        بگەڕانی ئاوایەکی بەرچاو بۆ کۆمپانیای هۆلدینگی پیشەسازی لە ڕووناکبونی کاروگێڕەکان
        </p>
      </div>
      <div className="Vissions-item">
        <p className="Vissions-title">:دووەم</p>
        <p className="Vissions-txt">
        بەرزبوونی لە نێوان پینج یارێنکەی بە ئیشکراوی بە دەستگرتنەوەی دەبڕینە بەرزتایەکی بەرفراوان.

        </p>
      </div>
      </div>
      <div className="Vissions-row2">
      <div className="Vissions-item">
        <p className="Vissions-title">:سێیەم</p>
        <p className="Vissions-txt">
        
        </p>
      </div>
    </div>
    
  </div>
</div>

      <div ref={ValuesRef} className="Values-section">
        <p className="s-sub-title">نرخییەکان</p>
        <div className="Values-row">
       <p className="Values-txt">
       <span className="Values-title">:ئه‌زموون و ڕازیکردنی کڕیارەکان</span> 
       < br/>
       ئێمە جەنگی بە ڕاگرتنی ئیمان و پاراستنی دلخۆشی لە نێوان هەموو هاوبەشەکانمان دەکەین، هەر ئه‌وه‌بێ کڕیارانی بەرزقیمت یان هاوبەشانی بازاڕە محترم بێن
       </p>
       <p className="Values-txt">
       <span className="Values-title">:جوێکی باوەڕپێدانە و هەوڵەندە لە كۆمپانیا:</span>
       < br/>
       ئێمە جیهانی کار دەسەردەکەین کە بە شێوەیەکی ڕێگەدانە و هاوکاری بە جیهانی کار دەدات و بە هەموار گەشەپێدانی ئەندامانی کارەکانەوە.
       </p>
       <p className="Values-txt">
       <span className="Values-title">:گەشەپێدانی تایبەتی</span> 
       < br/>
       ئێمە بە پەرۆشی هر ئەندام بۆ ئەندامانی کار پەیوەندیدانی تەواوبونی جیهانی خزمەت و کەرەها ئەرکەکانی گەشەپێدانی تایبەتی پێش بکەین.
       </p>
         <p className="Values-txt">
         <span className="Values-title">:گەشەپێدانی ئایینی</span>
       < br/>
       ئۆرگەنایزی ئێمە وەک ئۆرگەنایزەری فێرکراو، پاراستنی هەردەم بە پەیوەندییەکی تەواوی گەشەپێدان، چۆنە وەیەرەگەرتنی سەرەوە.
       </p>
       </div>
      </div>

      <div ref={prsons} className="about-page-ab">
      <div className="container123">
        <p className="s-sub-title">ئەندامانی هەیەتی بەرەوپێش</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
  className="cards-container-sb"
>
  {characters.map((characters, index) => (
    <SwiperSlide key={index}>
      <div className="card-ab">
        <img src={characters.image} alt="Profile" className="profile-image-ab" />
        <h2 className="name-ab">{characters.name}</h2>
        <p className="description-ab">{characters.description}</p>
        <div className="social-icons-ab">
          <a href={characters.links.linkedin} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href={characters.links.twitter} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href={characters.links.instagram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={characters.links.telegram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href={characters.links.whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
      </div>
      </div>
      <div ref={contactRef} className="contact-section">
    <p className="s-sub-title">پەیوەندیدان بە ئێمە</p>
    <div className="contact-content">
    <div className="contact-infoer">
  <div className="contact-info">
   
  ئه‌رات - شەقام هاشمی ڕەفسانجانی بۆ ڕۆژئاوا - بەردەست لەگەڵ
    <FaMapMarkerAlt style={{ marginLeft: "14px", color: "#ff8c42"}} />
    <br />
    داخلی کارەبازە فجر ١٢ - پلاک ٢١ - قەتاب ١
  </div>	
  <div className="contact-info">
    info@ptrahro.com
    <FaEnvelope style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
  <div className="contact-info">
    +982191004056
    <FaPhoneAlt style={{ marginLeft: "14px", color: "#ff8c42" }} />
  </div>	
</div>
        <form className="contact-form">
           <div className="form-grid">
  <label>
    <div className='Requiop'>
    <span>ناو و ناوی باوکی</span><span className="required">*</span>
    </div>
    <input type="text" name="fullname" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>ژمارەی مۆبایل</span><span className="required">*</span>
    </div>
    <input type="tel" name="phone" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>پۆستی ئەلیکترۆنی</span>
  </div>
    <input type="email" name="email" />
  </label>
  <label>
    <span>سەرپەڕە</span>
    <input type="text" name="subject" />
  </label>
</div>
<label>
<div className='Requiop'>
  <span>پەیام</span><span className="required">*</span>
 </div>
  <textarea name="message" rows="5" required></textarea>
</label>
<button type="submit">پەیامی بنێرە</button>
        </form>
    </div>
</div>

    </div>
  );
}

export default AboutPageku;