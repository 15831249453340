import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './Dashboard.css';
import Cookies from 'js-cookie'; 
import jalaali from 'jalaali-js';
import { useNavigate } from 'react-router-dom';
 // برای کار با تاریخ شمسی// برای گرفتن کوکی‌ها
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { faUserCircle, faCoins, faClock, faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
 import Loading from '../Loading'; // کامپوننت لودینگ
 import CryptoJS from 'crypto-js';
// تعریف نگاشت سرویس‌های فارسی به نام جداول دیتابیس
const serviceMapping = {
  'سرویس 1': { tableName: 'service1', savcoin: 10, duration: 2 },
  'سرویس 2': { tableName: 'service2', savcoin: 20, duration: 4 },
  'سرویس 3': { tableName: 'service3', savcoin: 15, duration: 1 },
  'سرویس 4': { tableName: 'service4', savcoin: 30, duration: 3 },
  'سرویس 5': { tableName: 'service5', savcoin: 25, duration: 1 },
  'سرویس 6': { tableName: 'service6', savcoin: 40, duration: 1 },
  'سرویس 7': { tableName: 'service7', savcoin: 50, requiresCoins: true, duration: 3 }
};

const Dashboard = () => {
  const [expandedService, setExpandedService] = useState(null); 
  const [isMobile, setIsMobile] = useState(false);

// توابعی برای مدیریت کلیک و نمایش کشو
const Header = ({ name, coins, remainingTimeParts }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};
}
const toggleServiceDetails = (index) => {
    setExpandedService(expandedService === index ? null : index);
};
const checkIsMobile = () => {
  setIsMobile(window.innerWidth <= 768);
};

// بررسی وضعیت موبایل با تغییر اندازه صفحه
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // اگر عرض صفحه کمتر از 768 پیکسل باشد، حالت موبایل فعال شود
  };
  handleResize(); // اجرای اولیه
  window.addEventListener('resize', handleResize); // افزودن listener برای تغییرات صفحه
  return () => window.removeEventListener('resize', handleResize); // پاک کردن listener هنگام خروج
}, []);

// استفاده از useEffect برای شناسایی تغییر در عرض صفحه
useEffect(() => {
  checkIsMobile(); // بررسی اولیه

  // Listener برای تغییرات در اندازه صفحه
  window.addEventListener('resize', checkIsMobile);

  // حذف listener هنگام خروج از کامپوننت
  return () => window.removeEventListener('resize', checkIsMobile);
}, []);


  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const secretKey = "ExaByte13830908"
  const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};

const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);
const [isMenuOpen, setIsMenuOpen] = useState(false);

const toggleMenu = () => {
  setIsMenuOpen(!isMenuOpen);
};
useEffect(() => {
  const ctoken = Cookies.get('token');
  if (!ctoken) {
    navigate('/');
  }
}, [phoner]);
  const name = Cookies.get('TokenN');
  useEffect(() => {
     const CheckLogined = async () => {
      const ctoken = Cookies.get('token');
      try {
          const response = await fetch('http://141.11.21.74:5000/api/token-check', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ phone_number: phoner, token: ctoken })
          });
            const data = await response.json();
            if (!data.success) {
                navigate('/'); // انتقال به صفحه /
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    CheckLogined();
  }, [phoner]);
  const [checked, setchecked] = useState([]);
  useEffect(() => {
    const code = Cookies.get('coder');
    const handleLogin = async (e) => {
    try {
      const response = await fetch('http://141.11.21.74:5000/api/loginn', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phone_number: phoner, code: code }),
      });
  
      if (!response.ok) {
        setchecked(true)
          navigate('/login')
      } else {
        setchecked(false)
        setLoading(false);
      }
  } catch (error) {
      console.error('Error:', error);
      alert(`مشکلی پیش آمد: ${error.message}`);
  }
    }
  }, [])
  
  useEffect(() => {
    const checkk = async () => {
        try {
            const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const responseBody = await response.text();
            if (!response.ok) {
                const errorData = JSON.parse(responseBody);
                throw new Error(errorData.error);
            }
            const data = JSON.parse(responseBody);
            const stage = data.Stage;
            if (checked) {
            if (stage === '3') {
                navigate('/next-page'); // انتقال به صفحه /
            } else  if (stage === '4') {
                navigate('/upload-video'); // انتقال به صفحه /
            }else  if (stage === '2') {
              navigate('/second-page'); // انتقال به صفحه /
          } else  if (stage === '5') {
                navigate('/wait'); // انتقال به صفحه /
            } else if (stage === '6') {
                navigate('/code');
            }
          }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    checkk();
}, [checked])
useEffect(() => {
  setTimeout(Cookies.remove('code'), 1000 * 60 * 20);
}, [])
  const [services, setServices] = useState([
    { name: 'سرویس 1', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 2', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 3', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 4', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 5', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 6', isActive: false, status: null, requestDate: null , remaining: null, used: null},
    { name: 'سرویس 7', isActive: false, status: null, requestDate: null, requiresCoins: true , remaining: null, used: null}
  ]);

  const [coins, setCoins] = useState(0);
  const [expireDate, setExpireDate] = useState(null); // تاریخ اتمام اشتراک

  // دریافت وضعیت سرویس‌های کاربر از دیتابیس هنگام بارگذاری صفحه
  useEffect(() => {
    const fetchServiceStatuses = async () => {
      try {
        const response = await fetch(`http://141.11.21.74:5000/api/get-service-statuses?phone_number=${phoner}`);
        const data = await response.json();

        // به روزرسانی وضعیت سرویس‌ها بر اساس داده‌های دریافتی از دیتابیس
        const updatedServices = services.map((service) => {
          const { tableName } = serviceMapping[service.name];
          const serviceData = data[tableName]; // گرفتن داده مربوط به هر سرویس

          if (serviceData) {
            // تغییر فرمت تاریخ به "روز/ماه/سال"
            const formattedDate = serviceData.date ? serviceData.date.split('T')[0] : null;
            const [year, month, day] = formattedDate.split('-'); // جدا کردن سال، ماه، روز
            const displayDate = `${year}/${month}/${day}`; // تنظیم فرمت "روز/ماه/سال"
            return {
              ...service,
              status: serviceData.status === 'accepted' ? 'فعال‌شده' : 'در انتظار تایید',
              requestDate: displayDate, // نمایش تاریخ به فرمت دلخواه
              isActive: serviceData.status === 'accepted',
              used: serviceData.used
            };
          }
          return service;
        });

        setServices(updatedServices);
      } catch (error) {
        console.error('Error fetching service statuses:', error);
      }
    };

    const fetchSavcoin = async () => {
      try {
        const response = await fetch(`http://141.11.21.74:5000/api/get-savcoin?phone_number=${phoner}`);
        const data = await response.json();
        setCoins(data.savcoin);
      } catch (error) {
        console.error('Error fetching savcoin:', error);
      }
    };
// اضافه کردن وضعیت برای هر سرویس

    const fetchExpireDate = async () => {
      try {
        const response = await fetch(`http://141.11.21.74:5000/api/get-expire-date?phone_number=${phoner}`);
        const data = await response.json();
        setExpireDate(new Date(data.expiredate)); // ذخیره تاریخ اتمام اشتراک
      } catch (error) {
        console.error('Error fetching expire date:', error);
      }
    };

    fetchServiceStatuses();
    fetchSavcoin();
    fetchExpireDate();
  }, []); // بارگذاری فقط یک بار هنگام باز شدن صفحه
  useEffect(() => {
    const updatedServices = services.map((service) => {
      if (service.isActive && service.requestDate) {
        const requestDate = service.requestDate;
        const durationInMonths = serviceMapping[service.name].duration;
  
        // تبدیل تاریخ شمسی به میلادی
        const [year, month, day] = requestDate.split('/').map(Number);
        const { gy, gm, gd } = jalaali.toGregorian(year, month, day);
  
        // ساختن شیء Date برای تاریخ شروع
        const startDate = new Date(gy, gm - 1, gd);
  
        // اضافه کردن مدت زمان به تاریخ شروع
        const endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + durationInMonths);
  
        // محاسبه زمان باقی‌مانده تا تاریخ پایان
        const now = new Date();
        const timeRemaining = endDate.getTime() - now.getTime();
  
        // تبدیل زمان باقی‌مانده به ماه، روز، ساعت و دقیقه
        let remainingTime = '';
        if (timeRemaining > 0) {
          const remainingDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
          const remainingHours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          remainingTime = `${remainingDays} روز و ${remainingHours} ساعت باقی‌مانده`;
        } else {
          remainingTime = 'مهلت به پایان رسیده است';
        }
  
        return {
          ...service,
          remaining: remainingTime
        };
      }
      return service;
    });
  
    // بررسی اینکه آیا services واقعاً تغییر کرده‌اند
    if (JSON.stringify(updatedServices) !== JSON.stringify(services)) {
      setServices(updatedServices);
    }
  }, [services]);
  
  
  // تابع برای محاسبه مدت زمان باقی‌مانده
  const calculateRemainingTime = () => {
    if (!expireDate) return [];
  
    // تبدیل تاریخ شمسی به میلادی
    const [expireYear, expireMonth, expireDay] = expireDate.toISOString().split('T')[0].split('-').map(Number);
    const gExpire = jalaali.toGregorian(expireYear, expireMonth, expireDay);
    
    // ایجاد شیء تاریخ میلادی بر اساس تاریخ انقضای شمسی
    const expireDateGregorian = new Date(gExpire.gy, gExpire.gm - 1, gExpire.gd);
  
    // تاریخ کنونی
    const now = new Date();
  
    // محاسبه مدت زمان باقی‌مانده به میلی‌ثانیه
    const remainingMilliseconds = expireDateGregorian.getTime() - now.getTime();
  
    // اگر زمان باقی‌مانده منفی باشد، اشتراک تمام شده است
    if (remainingMilliseconds <= 0) {
      return ['اشتراک شما به پایان رسیده است'];
    }
  
    // محاسبه سال، ماه، روز و ساعت‌های باقی‌مانده
    let remainingTimeParts = [];
    
    const nowJalali = jalaali.toJalaali(now.getFullYear(), now.getMonth() + 1, now.getDate());
    const expireJalali = jalaali.toJalaali(expireDateGregorian.getFullYear(), expireDateGregorian.getMonth() + 1, expireDateGregorian.getDate());
  
    let remainingYears = expireJalali.jy - nowJalali.jy;
    let remainingMonths = expireJalali.jm - nowJalali.jm;
    let remainingDays = expireJalali.jd - nowJalali.jd;
  
    if (remainingDays < 0) {
      remainingMonths -= 1;
      remainingDays += 30; // به عنوان فرض، تعداد روزها ۳۰ در نظر گرفته شده است، این بخش ممکن است برای ماه‌های مختلف نیاز به تنظیم داشته باشد
    }
  
    if (remainingMonths < 0) {
      remainingYears -= 1;
      remainingMonths += 12;
    }
  
    if (remainingYears > 0) {
      remainingTimeParts.push(`${remainingYears} سال`);
    }
    if (remainingMonths > 0) {
      remainingTimeParts.push(`${remainingMonths} ماه`);
    }
    if (remainingDays > 0) {
      remainingTimeParts.push(`${remainingDays} روز`);
    }
  
    // محاسبه ساعت‌های باقی‌مانده
    const remainingHours = Math.floor((remainingMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (remainingHours > 0) {
      remainingTimeParts.push(`${remainingHours} ساعت`);
    }
  
    return remainingTimeParts.length > 0 ? remainingTimeParts : ['زمانی باقی‌نمانده'];
  };
  
  // فراخوانی تابع
  const remainingTimeParts = calculateRemainingTime();

  const requestServiceActivation = async (index) => {
    if (services[index].requiresCoins && coins < 100) {
      alert('برای فعال‌سازی این سرویس، نیاز به کوین بیشتری دارید');
      return;
    }
  
    // گرفتن تاریخ میلادی فعلی
    const currentDate = new Date();
  
    // تبدیل تاریخ میلادی به شمسی
    const jalaliDate = jalaali.toJalaali(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
  
    // ساختن فرمت تاریخ شمسی به صورت رشته
    const shamsiFormattedDate = `${jalaliDate.jy}-${jalaliDate.jm < 10 ? '0' + jalaliDate.jm : jalaliDate.jm}-${jalaliDate.jd < 10 ? '0' + jalaliDate.jd : jalaliDate.jd}`;
  
    // گرفتن نام معادل انگلیسی جدول سرویس از نگاشت
    const { tableName, savcoin } = serviceMapping[services[index].name];
  
    // ارسال درخواست به سرور برای ثبت تاریخ شمسی در دیتابیس
    try {
      const response = await fetch('http://141.11.21.74:5000/api/activate-service', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          serviceName: tableName, // نام انگلیسی جدول
          phone_number: phoner,
          name: name,
          status: 'requested',
          date: shamsiFormattedDate // تاریخ شمسی ثبت می‌شود
        })
      });
      
      if (response.ok) {
        // به‌روزرسانی کوین و وضعیت سرویس
        await fetch('http://141.11.21.74:5000/api/update-savcoin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            phone_number: phoner,
            savcoin: savcoin
          })
        });
  
        setServices(services.map((service, i) =>
          i === index ? { ...service, status: 'در انتظار تایید', requestDate: shamsiFormattedDate } : service
        ));
        setCoins(coins + savcoin);
      }
    } catch (error) {
      console.error('Error in requesting service activation:', error);
    }
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};
  return (
    <div className="page-container">
        <div className="dashboard-container">
        <div className="dashboard-info">
      <h3>{name}</h3>
      {isMobile ? (
        <>
          <FontAwesomeIcon 
            icon={faBars} 
            className="menu-icon" 
            onClick={toggleMenu} 
          />

          {/* منوی کشویی فقط در حالت موبایل */}
          {isMenuOpen && (
       <div className={`sidebar-menu ${isMenuOpen ? 'open' : ''}`}>
       <FontAwesomeIcon 
         icon={faTimes} 
         className="close-icon" 
         onClick={toggleMenu} 
       />
       <div className="menu-content">
         <div className="coins">
           <div className="label">کوین‌ها:</div>
           <div className="value">{coins}</div>
         </div>
         <div className="subscription-timer">
           <div className="text-container">
             <span>زمان باقی‌مانده از اشتراک:</span>
             <span className="remaining-time"> {remainingTimeParts.join(' و ')}</span>
           </div>
         </div>
       </div>
     </div>
     
          )}
        </>
      ) : (
        // نمایش اطلاعات به‌صورت ثابت در دسکتاپ
        <div className="desktop-info">
          <div className="coins">
            <FontAwesomeIcon icon={faCoins} /> کوین‌ها: {coins}
          </div>
          <div className="subscription-timer">
            <FontAwesomeIcon icon={faClock} /> زمان باقی‌مانده از اشتراک: 
            <div>{remainingTimeParts.join(' و ')}</div>
          </div>
        </div>
      )}
    </div>
            <div className="dashboard-content">
                <div className="services-list">
                    <h2>سرویس‌های شما</h2>
                 {services.map((service, index) => {
  const isExpired = service.remaining === 'مهلت به پایان رسیده است';
  const serviceClass = service.isActive 
    ? 'active' 
    : service.status === 'در انتظار تایید'
    ? 'pending'
    : isExpired
    ? 'expired'
    : ''; 

  return (
    
    <div key={index} className={`service-item ${serviceClass}`}>
      <div className="service-header">
        <span>{service.name}</span>
        <button
          onClick={() => requestServiceActivation(index)}
          className={`service-button ${
            service.used === 'yes' ? 'used-button' : 
            isExpired ? 'expired-button' :
            service.isActive ? 'active-button' :
            service.status === 'در انتظار تایید' ? 'pending-button' : 'default-button'
          }`}
          disabled={service.isActive || service.status === 'در انتظار تایید' || service.used === 'yes' || isExpired}
        >
          {service.used === 'yes'
            ? 'استفاده شده'
            : isExpired
            ? 'منقضی شده'
            : service.isActive
            ? `فعال از: ${service.requestDate}`
            : service.status === 'در انتظار تایید'
            ? 'در انتظار تایید'
            : 'فعال‌سازی'}
        </button>
        {isMobile && service.isActive && service.used !== 'yes' && !isExpired && (
    <div 
        className="service-toggle" 
        onClick={() => toggleServiceDetails(index)} 
        aria-expanded={expandedService === index ? 'true' : 'false'}
    >
        <FontAwesomeIcon 
            icon={faChevronDown} 
            className="toggle-icon" 
        />
    </div>
)}
      </div>

      {/* کشو برای زمان باقی‌مانده */}
      {expandedService === index && (
        <div className="service-details">
          <p>زمان باقی‌مانده: {service.remaining}</p>
        </div>
      )}
    </div>
  );
})}
                </div>
            </div>
        </div>
    </div>
);

};

export default Dashboard;
