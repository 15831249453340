import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './AboutPage.css';
import { Autoplay } from 'swiper/modules';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa"
const partners = [
  {
    image: "/images/post-s.png",
    link: "https://www.post.ir/"
  },
  {
    image: "/images/seda-va-sima.png",
    link: "https://www.pririb.ir"
  },
  {
    image: "/images/Mli-Bank.png",
    link: "https://www.bmi.ir/"
  },
  {
    image: "/images/Esteghlal Malasani.png",
    link: "https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87_%D9%81%D9%88%D8%AA%D8%A8%D8%A7%D9%84_%D8%A7%D8%B3%D8%AA%D9%82%D9%84%D8%A7%D9%84_%D9%85%D9%84%D8%A7%D8%AB%D8%A7%D9%86%DB%8C"
  },
  {
    image: "/images/Nedex.png",
    link: "https://nedex.ir/"
  },
  {
    image: "/images/Ariyana.png",
    link: ""
  }
];
const company = [
  {
    image: "/images/Bim.png",
    name: "Bimcheh",
    position: "",
    description: "Bu belgeye olan önemini göz önünde bulundurarak, Bimcheh markası dijital platformlar üzerinden hizmet sunarak kullanıcı memnuniyetini artırmayı hedefleyen bu sektörün önde gelen oyuncularından biri olarak kabul edilmektedir.",
    links: {
      website: "http://banian-vp.com",
    }
  },
  {
    image: "/images/Sav.png",
    name: "Sav",
    position: "",
    description: "Müşteri sadakatinin önemi ve çoğu sektördeki yoğun rekabet ortamı göz önüne alındığında, müşteri kulübü kurmak hizmet sektöründe kaçınılmaz bir hale gelmiştir. Patra Rahro İran Ticaret Şirketi, tüm müşterilerinin Patra Rahro değer zinciri dışındaki hizmetlerden yararlanabilmesi için bir alt kuruluş kurmuştur.",
    links: {
      website: "https://www.iransavclub.com",
    }
  },
  {
    image: "/images/VarmiHome.png",
    name: "Varmi",
    position: "",
    description: "Varmi, ürünlerini en yüksek kalite ve modern tasarımla üretmek için her türlü çabayı göstermiştir. Varmi, birkaç yıldır mutfak eşyaları üretimi alanında faaliyet göstermekte ve ürün yelpazesini genişletmeyi hedeflemektedir.",
    links: {
      website: "https://varmihome.ir",
    }
  },
  {
    image: "/images/CityKitchen.png",
    name: "Mutfak Şehri",
    position: "",
    description: "Bu şirket, ev ve kişisel bakım ürünleri satan 400'den fazla telefon satış temsilcisi ile tele-pazarlama alanında faaliyet göstermektedir.",
    links: {
      website: "https://shahreashpazkhaneh.ir",
    }
  },
  {
    image: "/images/LakPosht.png",
    name: "LakPosht",
    position: "",
    description: "170'den fazla şehirde temsilcilikleri bulunan LakPosht, yaklaşık 300 kuryeyle birlikte diğer dağıtım şirketleri ve İran İslam Cumhuriyeti Posta Şirketi ile iş birliği yaparak mal ve ürün dağıtımı yapmaktadır.",
    links: {
      website: "lakpost.ir",
    }
  },
  {
    image: "/images/PatraTrade.png",
    name: "Patra Ticaret",
    position: "",
    description: "Yeni kurulan bir şirket olarak bu kuruluş, holdingin ticari ve uluslararası faaliyetlerinden sorumludur.",
    links: {
      website: "https://patratrade.com/",
    }
  }
];

const characters = [
  {
    image: "/images/Parkhande.jpg",
    name: "Taha ParKhande",
    position: "CEO",
    description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Payam Nankali",
    position: "عضو هیئت مدیره",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Dr Rad Saeed",
    position: "عضو هیئت مدیره",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Dr Ebrahimi",
    position: "وکیل",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  {
    image: "/images/Parkhande.jpg",
    name: "Sobhan Laleh",
    position: " ",
        description: "Discription,Discription, Discription, Discription ...",
    links: {
      linkedin: "https://linkedin.com",
      twitter: "https://twitter.com",
      instagram: "https://instagram.com",
      telegram: "https://telegram.org",
      whatsapp: "https://whatsapp.com"
    }
  },
  // بقیه داده‌های کاراکترها
];
function Sidebar({ activeTab, onTabClick }) {
  const tabs = ["Bizim Hikayemiz", "Alt Şirketler", "Ticari Ortaklar", "Misyonumuz", "Vizyonumuz", "Değerlerimiz", "Yönetim Kurulu", "Bize Ulaşın"];
  return (
    <div className="sidebar-ab">
      <div
        className="progress-line-ab"
        style={{ height: `${((activeTab + 1) / tabs.length) * 100}%` }}
      ></div>
      <div className="tabs-ab-en">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab-ab ${index === activeTab ? "active-ab" : ""}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

function AboutPagetr() {
  const [activeTab, setActiveTab] = useState(0);
  const brandStoryRef = useRef(null);
  const subCompanysRef = useRef(null);
  const partnersRef = useRef(null); // مرجع برای بخش شرکای تجاری
  const boardRef = useRef(null);
  const MissionsRef = useRef(null);
  const VissionsRef = useRef(null);
  const ValuesRef = useRef(null);
  const prsons = useRef(null);
  const contactRef = useRef(null);


  useEffect(() => {
      const handleScroll = () => {
        const sections = [
          brandStoryRef.current,
          subCompanysRef.current,
          partnersRef.current,
          boardRef.current,
          MissionsRef.current,
          VissionsRef.current,
          ValuesRef.current,
          prsons.current,
          contactRef.current,
        ];
    
        // فیلتر کردن بخش‌هایی که مقداردهی نشده‌اند
        const sectionOffsets = sections
          .filter(section => section) // حذف بخش‌های null
          .map(section => section.getBoundingClientRect().top);
    
        const activeIndex = sectionOffsets.findIndex(
          offset => offset >= 0 && offset < window.innerHeight / 2
        );
    
        if (activeIndex !== -1) {
          setActiveTab(activeIndex);
        }
      };
    
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTabClick = (index) => {
      const refs = [brandStoryRef, subCompanysRef, partnersRef, MissionsRef, VissionsRef, ValuesRef, prsons, contactRef];

      
      // بررسی وجود ref قبل از scrollIntoView
      if (refs[index] && refs[index].current) {
        refs[index].current.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`Ref at index ${index} is not defined.`);
      }
    };
  return (
    <div className="All">
        <Sidebar activeTab={activeTab} onTabClick={handleTabClick} />

<div ref={brandStoryRef} className="brand-story-en">
  
        <p className="s-sub-title">Bizim Hikayemiz</p>
        <div className="brand-story-item">
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
          <p className="brand-story-text">
          Bu şirket, 2014 yılında TV alışverişinde faaliyetlerine başlamış ve giyim, spor ekipmanları, kozmetik ve ev aletleri gibi çeşitli kategorilerde ürünler satarak devam etmiştir.
            <br /><br />
            2020 yılında, Patra Rahro Iranian Trading markası altında, Teflon tencere ve ev aletleri üretiminde değer zincirini tamamlama yolunda bir adım daha atarak üretim alanına girmiştir.
          </p>
        </div>

        <div className="brand-story-item">
          <p className="brand-story-text">
          Yolculuğuna devam ederken kalite ve hizmetlerini geliştirme amacıyla, kendi lojistik ağını kurmaya karar vermiş ve dağıtım platformunu ülkenin %50'sinden fazlasını ve nüfusun %70'ini kapsayacak şekilde sağlamıştır. Şu anda müşteri kulübü kurarak müşteri sadakatini artırmak ve çevrimiçi ve dijital hizmetlerde daha fazla faaliyet göstermek için bir adım daha atmıştır. Ayrıca sigorta hizmetleri sunarak bu sektörde önde gelen oyunculardan biri haline gelmiştir.

          </p>
          <img src="/images/Patra.png" alt="Profile" className="brand-story-img" />
        </div>
      </div>

 
      <div ref={subCompanysRef} className="sub-companys">
      <div className="container123">
        <p className="s-sub-title">Alt Şirketler</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
          className="cards-container-sb"
        >
          {company.map((company, index) => (
            <SwiperSlide key={index}>
                <div className="card-sb">
    <img src={company.image} alt="Profile" className="profile-image-sb" />
    <h2 className="name-sb">{company.name}</h2>
    <p className="description-sb-en">{company.description}</p>
    <div className="website-button">
        <a href={company.links.website} target="_blank" rel="noopener noreferrer" className="site-link">
            <button className="site-button">
                <i className="fas fa-globe site-icon"></i>
            </button>
        </a>
    </div>
</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
      <div ref={partnersRef} className="partners-section">
        <p className="s-sub-title">Ticari Ortaklar</p>
        <div className="partners-logos">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer" key={index}>
              <img src={partner.image} alt={`Partner ${index + 1}`} className="partner-logo" />
            </a>
          ))}
        </div>
      </div>
      <div ref={MissionsRef} className="Missions-section">
        <p className="s-sub-title">Misyonumuz</p>
       <p className="Missions-txt-en">Müşteri odaklı bir vizyonla, hedefimiz kapsamlı ve tatmin edici bir alışveriş deneyimi sunmaktır. Bu yolculuk boyunca, farklı sınıflarda ürün ve hizmet sunmayı ve müşterilerimize kolay ve hızlı teslimatı sağlamayı taahhüt ediyoruz.</p>
      </div>
      <div ref={VissionsRef} className="Vissions-section">
  <p className="s-sub-title">Vizyonumuz</p>
  <div className="Vissions-content-en">
    <p className="Vissions-txt top-item-en">
    Patra Rahro Iranian Trading'in vizyonu üç ana dayanağa dayanır.
    </p>
    <div className="Vissions-row-en">
      <div className="Vissions-item-en">
        <p className="Vissions-title">Bir:</p>
        <p className="Vissions-txt-en">
        Önde gelen bir holding şirketi olmak, çeşitli endüstrilerde çeşitli şirketlerden oluşan bağlı kuruluşlara sahip olmak ve sürdürülebilirliği ve yeniliği daha da güçlendirebilmek.
        </p>
      </div>
      <div className="Vissions-item-en">
        <p className="Vissions-title-en">Altyapıyı:</p>
        <p className="Vissions-txt-en">
        Topluluk içinde en iyi 5 seçimden biri olmak, altyapı geliştirme ve akıllı teknoloji kullanımı yoluyla çeşitli sınıflarda ürünler ve hizmetler sunmak.
        </p>
      </div>
      </div>
      <div className="Vissions-row2-en">
      <div className="Vissions-item-en">
        <p className="Vissions-title">Üçüncü:</p>
        <p className="Vissions-txt-en">
        
        </p>
      </div>
    </div>
    
  </div>
</div>

      <div ref={ValuesRef} className="Values-section">
        <p className="s-sub-title">Değerlerimiz</p>
        <div className="Values-row">
       <p className="Values-txt-en">
       <span className="Values-title">Güven ve Paydaş Memnuniyeti:</span> 
       < br/>
       Değerli müşterilerimiz veya saygın iş ortaklarımız olsun, tüm paydaşlarımız arasında güven oluşturma ve memnuniyeti sağlama konusunda 
       </p>
       <p className="Values-txt-en">
       <span className="Values-title">Organizasyonda Aile ve Dostane Atmosfer:</span>
       < br/>
      Çalışanların bütünsel gelişimine adanmış, destekleyici bir aile gibi hareket eden bir çalışma ortamı oluşturuyoruz.
       </p>
       <p className="Values-txt-en">
       <span className="Values-title">Kişisel Gelişim:</span> 
       < br/>
       Organizasyonumuzdaki her bireyin büyümesini destekliyoruz. Bireylerimize, beceri eğitimi, mentorluk ve sürekli öğrenme ve kendini geliştirme kültürünü teşvik eden fırsatlar ve kaynaklar sağlıyoruz.
       </p>
         <p className="Values-txt-en">
         <span className="Values-title">Organizasyonel Gelişim:</span>
       < br/>
       Öğrenen bir organizasyon olarak, sürekli iyileşme ve büyümeye bağlıyız. Organizasyonel gelişimi teşvik eden süreçlere, sistemlere ve stratejilere yatırım yapıyoruz. Çeviklik sayesinde yenilikçi değişiklikleri esneklikle uygulamamızı sağlıyoruz.
       </p>
       </div>
      </div>

      <div ref={prsons} className="about-page-ab">
      <div className="container123">
        <p className="s-sub-title">Yönetim Kurulu</p>
        <Swiper
  modules={[Autoplay]}
  spaceBetween={30}
  slidesPerView={3} // پیش‌فرض
  autoplay={{ delay: 3000, disableOnInteraction: false }}
  breakpoints={{
    1024: {
      slidesPerView: 3, // برای صفحه‌نمایش‌های بزرگ
    },
    768: {
      slidesPerView: 2, // برای تبلت‌ها
    },
    576: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    },
    200: {
      slidesPerView: 1, // برای گوشی‌های موبایل کوچک
    }
  }}
  className="cards-container-sb"
>
  {characters.map((characters, index) => (
    <SwiperSlide key={index}>
      <div className="card-ab">
        <img src={characters.image} alt="Profile" className="profile-image-ab" />
        <h2 className="name-ab">{characters.name}</h2>
        <p className="description-ab-en">{characters.description}</p>
        <div className="social-icons-ab">
          <a href={characters.links.linkedin} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href={characters.links.twitter} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a href={characters.links.instagram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href={characters.links.telegram} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href={characters.links.whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
      </div>
      </div>
      <div ref={contactRef} className="contact-section-en">
    <p className="s-sub-title-en">Bize Ulaşın</p>
    <div className="contact-content-en">
    <div className="contact-infoer">
  <div className="contact-info-en">
  <FaMapMarkerAlt style={{ marginRight: "14px", color: "#ff8c42"}} />   
  Ararat – Hashemi Rafsanjani Otoyolu, Doğudan Batıya – Kurdistan girişinden önce
    <br />
    Fajr Mahallesi – Fajr 12 – No. 21 – Kat 1
    
  </div>	
  <div className="contact-info-en">
  <FaEnvelope style={{ marginRight: "14px", color: "#ff8c42" }} />
    info@ptrahro.com

  </div>	
  <div className="contact-info-en">
  <FaPhoneAlt style={{ marginRight: "14px", color: "#ff8c42" }} />
    +982191004056

  </div>	
</div>
        <form className="contact-form-en">
           <div className="form-grid">
  <label>
    <div className='Requiop'>
    <span>Name & LastName</span><span className="required">*</span>
    </div>
    <input type="text" name="fullname" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>Phone Number</span><span className="required">*</span>
    </div>
    <input type="tel" name="phone" required />
  </label>
  <label>
  <div className='Requiop'>
    <span>Email</span>
  </div>
    <input type="email" name="email" />
  </label>
  <label>
    <span>Subject</span>
    <input type="text" name="subject" />
  </label>
</div>
<label>
<div className='Requiop'>
  <span>Message</span><span className="required">*</span>
 </div>
  <textarea name="message" rows="5" required></textarea>
</label>
<button type="submit">Send</button>
        </form>
    </div>
</div>

    </div>
  );
}

export default AboutPagetr;