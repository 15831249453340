import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './SecondPage.css';
import { cityCodes } from './cityCodes';
import Toast from './popup';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Loading from '../Loading'; // کامپوننت لودینگ


const SecondPage = ({ userName, phoneNumber, stage }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showToast, setShowToast] = useState(false);
const [toastMessage1, setToastMessage1] = useState('');
const [toastMessage2, setToastMessage2] = useState('');
const secretKey = "ExaByte13830908"
const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);
    const [formData, setFormData] = useState({
        phone_number: phoner,
        name: userName,
        gender: '',
        father_name: '',
        national_id: '',
        province: '',
        city: '',
        full_address: '',
        front_id_image: '',
        back_id_image: '',
        id_fish_image: '',
        postal_code: '',
        birth_year: '', birth_month: '', birth_day: '', birth_date: '',
        marital_status: '',
        spouse_name: '',
        spouse_birth_year: '', spouse_birth_month: '', spouse_birth_day: '', spouse_birth_date: '2024-10-24',
        bounced_check: '',
        delayed_payment: '',
        military_service_status: '',
        job: '',
        education: '',
        second_mobile_number: '',
        landline_number: '',
        firstApprove: '',
        stage: '2'
    });
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [checked, setchecked] = useState([]);
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    console.log("WTF")
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                            try {
                                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                                const responseBody = await response.text();
                                if (!response.ok) {
                                    const errorData = JSON.parse(responseBody);
                                    throw new Error(errorData.error);
                                }
                                const data1 = JSON.parse(responseBody);
                                const stage1 = data1.Stage;
                                if (stage1 === '7'){
                                setchecked(true)
                                navigate('/dashboard')
                                }
                            } catch (error) {

                            }
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
    }, []);
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-user-name?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const userName = data.userName;
                if (userName) {
                Cookies.set('TokenN', userName);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();

    }, [])
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (checked === false) {
                if (stage === '3') {
                    navigate('/next-page'); // انتقال به صفحه /
                } else  if (stage === '4') {
                    navigate('/upload-video'); // انتقال به صفحه /
                } else  if (stage === '5') {
                    navigate('/wait'); // انتقال به صفحه /
                } else if (stage === '6') {
                    navigate('/code');
                }   else if (stage === '7') {
                    navigate('/login');
                } 
            }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();

    }, [checked])
   
    const [birthYears, setBirthYears] = useState('');
const [birthMonths, setBirthMonths] = useState('');
const [birthDays, setBirthDays] = useState('');
const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    const regex = /^[0-9]*$/;
    if (name === 'city') {
        const cityCode = cityCodes[value] || '';
        setFormData({ ...formData, [name]: value, landline_number: cityCode });
    } else     if (name === 'landline_number') {
        if (regex.test(value)) {
            e.target.setCustomValidity('');
            const currentCityCode = formData.landline_number.slice(0, 3);
            const updatedNumber = currentCityCode + value.slice(3);
            setFormData({ ...formData, [name]: updatedNumber });
        } else {
            e.target.setCustomValidity('شما نمیتونید از حروف استفاده کنید');
        }
    } else {
        setFormData({ ...formData, [name]: value });
    }

};


    const [phone, setPhone] = useState('');
    const [r, setR] = useState('');
    const [cipheredText, setCipheredText] = useState('');
  
    const encrypt = (text, secretKey) => {
        const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
        const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
        return base64.replace(/\//g, '_').replace(/\./g, '-');
    };
    /* ادامه برای بقیه جداول سرویس... */
    
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-info?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    console.log("New User")
                }
                
                const data = JSON.parse(responseBody);
                const birthDate = data.birth_date.split('-');
const birthYear = birthDate[0];
const birthMonth = birthDate[1];
const birthDay = birthDate[2];
const birthDates = data.spouse_birth_date.split('-');
const birthYears = birthDates[0];
const birthMonths = birthDates[1];
const birthDays = birthDates[2];
                setFormData((prevState) => ({ ...prevState, 
gender: data.gender,
father_name: data.father_name,
national_id: data.national_id,
province: data.province,
city: data.city,
full_address: data.full_address,
postal_code: data.postal_code,
birth_year: birthYear, birth_month: birthMonth, birth_day: birthDay, birth_date: data.birth_date,
marital_status: data.marital_status,
spouse_name:data.spouse_name,
spouse_birth_year: birthYears, spouse_birth_month: birthMonths, spouse_birth_day: birthDays, spouse_birth_date: data.spouse_birth_date,
bounced_check: data.bounced_check,
delayed_payment: data.delayed_payment,
military_service_status: data.military_service_status,
job: data.job,
education: data.education,
second_mobile_number: data.second_mobile_number,
landline_number: data.landline_number
            }));
            setFormData((prevState) => ({ ...prevState, 
                front_id_image: data.front_id_image ? `http://141.11.21.74:5000/uploads/${data.front_id_image}` : null,
                back_id_image: data.back_id_image ? `http://141.11.21.74:5000/uploads/${data.back_id_image}` : null,
                id_fish_image: data.id_fish_image ? `http://141.11.21.74:5000/uploads/${data.id_fish_image}` : null
            }));
            setPreviewURLs({
                front_id_image: data.front_id_image ? `http://141.11.21.74:5000/uploads/${data.front_id_image}` : null,
                back_id_image: data.back_id_image ? `http://141.11.21.74:5000/uploads/${data.back_id_image}` : null,
                id_fish_image: data.id_fish_image ? `http://141.11.21.74:5000/uploads/${data.id_fish_image}` : null
            });
            
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();

    }, [])
    const [username, setUsername] = useState('');
    useEffect(() => {
        const getUsername = () => {
          const username = Cookies.get('TokenN');
          setUsername(username);
        };
        setTimeout(getUsername, 500);
      }, []);
    useEffect(() => {
        if (formData.birth_month) {
            const days = new Date(formData.birth_year || 1300, formData.birth_month, 0).getDate();
            setDaysInMonth(Array.from({ length: days }, (v, k) => k + 1));
        }
    }, [formData.birth_month, formData.birth_year]);

    useEffect(() => {
        if (formData.birth_year && formData.birth_month && formData.birth_day) {
            const birthDate = `${formData.birth_year}/${formData.birth_month}/${formData.birth_day}`;
            setFormData((prevState) => ({ ...prevState, birth_date: birthDate }));
        }
    }, [formData.birth_year, formData.birth_month, formData.birth_day]);
    useEffect(() => {
        if (formData.spouse_birth_month) {
            const days = new Date(formData.spouse_birth_year || 1300, formData.spouse_birth_month, 0).getDate();
            setDaysInMonth(Array.from({ length: days }, (v, k) => k + 1));
        }
    }, [formData.spouse_birth_month, formData.spouse_birth_year]);
    useEffect(() => {
        if (formData.spouse_birth_year && formData.spouse_birth_month && formData.spouse_birth_day) {
            const spouse_birthDate = `${formData.spouse_birth_year}/${formData.spouse_birth_month}/${formData.spouse_birth_day}`;
            setFormData((prevState) => ({ ...prevState, spouse_birth_date: spouse_birthDate }));
        }
    }, [formData.spouse_birth_year, formData.spouse_birth_month, formData.spouse_birth_day]);
    const years = Array.from({ length: 86 }, (v, k) => 1385 - k);
    const months = [
        { name: 'فروردین', value: '1' },
        { name: 'اردیبهشت', value: '2' },
        { name: 'خرداد', value: '3' },
        { name: 'تیر', value: '4' },
        { name: 'مرداد', value: '5' },
        { name: 'شهریور', value: '6' },
        { name: 'مهر', value: '7' },
        { name: 'آبان', value: '8' },
        { name: 'آذر', value: '9' },
        { name: 'دی', value: '10' },
        { name: 'بهمن', value: '11' },
        { name: 'اسفند', value: '12' },
    ];

    const provincesAndCities = {
        "آذربایجان شرقی": ["تبریز", "مراغه", "مرند"],
        "آذربایجان غربی": ["ارومیه", "خوی", "میاندوآب"],
        "اردبیل": ["اردبیل", "مشگین‌شهر", "پارس‌آباد"],
        "اصفهان": ["اصفهان", "کاشان", "نجف‌آباد"],
        "البرز": ["کرج", "طالقان", "نظرآباد"],
        "ایلام": ["ایلام", "دهلران", "مهران"],
        "بوشهر": ["بوشهر", "کنگان", "دیر"],
        "تهران": ["تهران", "ری", "ورامین"],
        "چهارمحال و بختیاری": ["شهرکرد", "بروجن", "فارسان"],
        "خراسان جنوبی": ["بیرجند", "قاین", "طبس"],
        "خراسان رضوی": ["مشهد", "نیشابور", "سبزوار"],
        "خراسان شمالی": ["بجنورد", "شیروان", "اسفراین"],
        "خوزستان": ["اهواز", "آبادان", "خرمشهر"],
        "زنجان": ["زنجان", "ابهر", "خدابنده"],
        "سمنان": ["سمنان", "شاهرود", "دامغان"],
        "سیستان و بلوچستان": ["زاهدان", "چابهار", "زابل"],
        "فارس": ["شیراز", "مرودشت", "جهرم"],
        "قزوین": ["قزوین", "تاکستان", "آبیک"],
        "قم": ["قم"],
        "کردستان": ["سنندج", "سقز", "مریوان"],
        "کرمان": ["کرمان", "رفسنجان", "جیرفت"],
        "کرمانشاه": ["کرمانشاه", "اسلام‌آباد غرب", "هرسین"],
        "کهگیلویه و بویراحمد": ["یاسوج", "گچساران", "دهدشت"],
        "گلستان": ["گرگان", "گنبدکاووس", "علی‌آباد کتول"],
        "گیلان": ["رشت", "انزلی", "لاهیجان"],
        "لرستان": ["خرم‌آباد", "بروجرد", "دورود"],
        "مازندران": ["ساری", "بابل", "آمل"],
        "مرکزی": ["اراک", "ساوه", "خمین"],
        "هرمزگان": ["بندرعباس", "قشم", "کیش"],
        "همدان": ["همدان", "ملایر", "نهاوند"],
        "یزد": ["یزد", "میبد", "اردکان"],
    };    
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const cities = formData.province ? provincesAndCities[formData.province] : [];
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!validTypes.includes(file.type)) {
                alert('فقط فرمت‌های jpg، png، و gif مجاز هستند');
                return;
            }
    
            if (file.size > 5 * 1024 * 1024) { // ۵ مگابایت
                alert('حجم فایل نباید بیشتر از ۵ مگابایت باشد');
                return;
            }
    
            setFormData((prevState) => ({
                ...prevState,
                [name]: file
            }));
            setPreviewURLs((prevState) => ({
                ...prevState,
                [name]: URL.createObjectURL(file)
            }));
        }
    };
    
    const handleUpload = async () => {
        const front_id_image = formData.front_id_image;
        const back_id_image = formData.back_id_image;
        const id_fish_image = formData.id_fish_image;
        const formDataToSend = new FormData();
        const secretKey = 'ExaByte830908';
        const encryptedText = encrypt(phoner, secretKey);
        formDataToSend.append('phoneNumber', phoner);
        formDataToSend.append('encryptedText', encryptedText);
    
        if (front_id_image) {
            formDataToSend.append('frontIdImage', front_id_image);
        }
        if (back_id_image) {
            formDataToSend.append('backIdImage', back_id_image);
        }
        if (id_fish_image) {
            formDataToSend.append('idFishImage', id_fish_image);
        }
    
        try {
            const response = await fetch('http://141.11.21.74:5000/upload-img', {
                method: 'POST',
                body: formDataToSend
            });
            const result = await response.json();
            alert(result.message);
        } catch (err) {
            console.error(err);
        }
    };
    
    
    const handleLandlineNumberChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*$/; // فقط اعداد
        if (regex.test(value)) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            if (value.length === 11) {
                e.target.setCustomValidity('');
            } else {
                e.target.setCustomValidity('شماره تلفن باید 11 رقم باشد');
            }
        } else {
            e.target.setCustomValidity('فقط اعداد مجاز هستند');
        }
    };
    
    const handleSecondMobileNumberChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\d*$/; // فقط اعداد
        if (regex.test(value)) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            if (value.length === 10 || value.length === 11) {
                e.target.setCustomValidity('');
            } else {
                e.target.setCustomValidity('شماره همراه باید 10 یا 11 رقم باشد');
            }
        } else {
            e.target.setCustomValidity('فقط اعداد مجاز هستند');
        }
    };
    const handleCityChange = (e) => {
        const { value } = e.target;
        const cityCode = cityCodes[value] || '';
        setFormData((prevState) => ({
            ...prevState,
            city: value,
            landline_number: cityCode, // افزودن کد شهر به فیلد تلفن ثابت
        }));
    };
    
    
        const isapprovedch = () => {
        if (formData.firstApprove === 'true'){
            return true;
        }
        else {
            return false;
        }
    }
    const isFormComplete = () => {
    if (formData.gender === "مرد" && formData.id_fish_image === ''){
        return (
            formData.father_name !== '' &&
            formData.national_id !== '' &&
            formData.province !== '' &&
            formData.city !== '' &&
            formData.full_address !== '' &&
            formData.front_id_image !== '' &&
            formData.back_id_image !== '' &&
            formData.postal_code !== '' &&
            formData.birth_year !== '' &&
            formData.marital_status !== '' &&
            formData.bounced_check !== '' &&
            formData.delayed_payment !== '' &&
            formData.military_service_status !== '' &&
            formData.job !== '' &&
            formData.education !== '' &&
            formData.second_mobile_number !== '' &&
            formData.landline_number !== ''
        );
        
        }else if (formData.gender === "مرد" && formData.id_fish_image !== ''){
            return (
            formData.father_name !== '' &&
            formData.national_id !== '' &&
            formData.province !== '' &&
            formData.city !== '' &&
            formData.full_address !== '' &&
            formData.id_fish_image !== '' &&
            formData.postal_code !== '' &&
            formData.birth_year !== '' &&
            formData.marital_status !== '' &&
            formData.bounced_check !== '' &&
            formData.delayed_payment !== '' &&
            formData.military_service_status !== '' &&
            formData.job !== '' &&
            formData.education !== '' &&
            formData.second_mobile_number !== '' &&
            formData.landline_number !== ''
            );
        } 
        else if (formData.gender !== "مرد" && formData.id_fish_image === ''){
            return (
            formData.father_name !== '' &&
            formData.national_id !== '' &&
            formData.province !== '' &&
            formData.city !== '' &&
            formData.full_address !== '' &&
            formData.front_id_image !== '' &&
            formData.back_id_image !== '' &&
            formData.postal_code !== '' &&
            formData.birth_year !== '' &&
            formData.marital_status !== '' &&
            formData.bounced_check !== '' &&
            formData.delayed_payment !== '' &&
            formData.job !== '' &&
            formData.education !== '' &&
            formData.second_mobile_number !== '' &&
            formData.landline_number !== ''
            );
        }
        else if (formData.gender !== "مرد" && formData.id_fish_image !== ''){
            return (
            formData.father_name !== '' &&
            formData.national_id !== '' &&
            formData.province !== '' &&
            formData.city !== '' &&
            formData.full_address !== '' &&
            formData.id_fish_image !== '' &&
            formData.postal_code !== '' &&
            formData.birth_year !== '' &&
            formData.marital_status !== '' &&
            formData.bounced_check !== '' &&
            formData.delayed_payment !== '' &&
            formData.job !== '' &&
            formData.education !== '' &&
            formData.second_mobile_number !== '' &&
            formData.landline_number !== ''
            );
        }
    };
    const [firstApprove, setFirstApprove] = useState('');

    const handleCheckboxChange = (e) => {
        setFirstApprove(e.target.checked ? 'true' : '');
    };
    const [previewURLs, setPreviewURLs] = useState({
        front_id_image: null,
        back_id_image: null,
        id_fish_image: null
    });
    const handleEdit = async (e) => {
        // کد تغییر اطلاعات
        const phone_number = phoner;
        const stage = "3";
        try {
            const response = await fetch('http://141.11.21.74:5000/api/edit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone_number, stage })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted');
        if (!isFormComplete()) {
            setToastMessage1('خطا');
            setToastMessage2('لطفاً تمام فیلدها را پر کنید.');
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 5000);
            return;
        } else if (isFormComplete()) {
            if (isapprovedch()) {
            setToastMessage1('موفقیت');
            setToastMessage2('فرم با موفقیت ارسال شد!');
            setShowToast(true);
            setError('');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/update-user-info', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...formData, userName })
                });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error);
                }
    

            } catch (error) {
                console.error('Error:', error);
                alert(`مشکلی پیش آمد: ${error.message}`);
            }
            handleUpload();
            // هدایت به صفحه بعد و ارسال داده‌های فرم
            handleEdit();
            navigate('/next-page', { state: { ...formData } });
        }
        else {
            setToastMessage1('خطا');
            setToastMessage2('لطفا صحت اطلاعات وارد شده را تایید کنید');
            setShowToast(true);
        }
    }
    };
    

    const closeModal = () => {
        setShowModal(false);
    };
    if (loading) {
        return <Loading />; // نمایش لودینگ تا زمانی که داده‌ها بارگذاری شوند
      }
    return (
        
<div className="second-page">
    <Header userName={username} activeTab = "پرونده شخصی"/>
    <main className="content">
        <h1>{username} عزیز، به باشگاه مشتریان ساو خوش آمدید</h1>
        <p>جهت سهولت در ارائه سرویس‌های باشگاه لطفا پرونده خود را تکمیل نمایید</p>
        <form onSubmit={handleSubmit} className="form-horizontal">
        <div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , marginLeft: '7px' }}>
        <div className="col-md-8 col-sm-8">
            <select className="form-control" id="gender" value={formData.gender} onChange={handleChange} name="gender">
                <option value="" disabled>جنسیت خود را انتخاب کنید</option>
                <option value="مرد">مرد</option>
                <option value="زن">زن</option>
            </select>
        </div>
        <label className="control-label" for="gender" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> جنسیت
        </label>
    </div>
</div>
        <div className="form-group">
            
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <div className="col-md-8 col-sm-8">
        <input type="text" className="form-control" id="father_name" placeholder="نام پدر" value={formData.father_name} onChange={handleChange} name="father_name"/>
    </div>
        <label className="control-label" for="father_name" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> نام پدر
        </label>
    </div>
    
</div>




            <div className="form-group">
            <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className="col-md-8 col-sm-8">
                    <input type="text" className="form-control" id="national_id" placeholder="کد ملی" value={formData.national_id} onChange={handleChange} name="national_id"/>
                </div>
                <label className="control-label" for="national_id" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> کد ملی
        </label>
            </div>
            </div>
            <div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '10px' }}>
    <div className="col-md-8 col-sm-8">
        <select className="form-control" id="province" value={formData.province} onChange={handleChange} name="province">
            <option value="" disabled>استان خود را انتخاب کنید</option>
            {Object.keys(provincesAndCities).map(province => (
                <option key={province} value={province}>{province}</option>
            ))}
        </select>
    </div>
        <label className="control-label" for="province" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> استان
        </label>
    </div>
   
</div>

<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '20px' }}>
    <div className="col-md-8 col-sm-8">
        <select className="form-control" id="city" value={formData.city} onChange={handleChange} name="city">
            <option value="" disabled>شهر خود را انتخاب کنید</option>
            {cities.map(city => (
                <option key={city} value={city}>{city}</option>
            ))}
        </select>
    </div>
        <label className="control-label" for="province" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> شهر
        </label>
    </div>
   
</div>
<div className="form-group">
            <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '22px' }}>
            <div className="col-md-8 col-sm-8">
                    <input type="text" className="form-control" id="full_address" placeholder="آدرس کامل" value={formData.full_address} onChange={handleChange} name="full_address"/>
                </div>
                <label className="control-label" for="full_address" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> آدرس کامل
        </label>
            </div>
            </div>
<div className="form-group">
  <label className="col-md-3 col-sm-2 control-label" htmlFor="front_id_image">
    عکس روی کارت ملی
  </label>
  <div className="col-md-9 col-sm-10 input-group">
    <label className="upload-label" htmlFor="front_id_image">
      {formData.front_id_image ? "آپلود دوباره" : "آپلود"}
    </label>
    <input
      type="file"
      className="file-upload"
      id="front_id_image"
      onChange={handleFileChange}
      name="front_id_image"
    />
    {formData.front_id_image && (
      <img
        src={
          formData.front_id_image instanceof File
            ? URL.createObjectURL(formData.front_id_image)
            : formData.front_id_image
        }
        alt=" "
        className="image-preview"
      />
    )}
  </div>
</div>

<div className="form-group">
  <label className="col-md-3 col-sm-2 control-label" htmlFor="back_id_image">
    عکس پشت کارت ملی
  </label>
  <div className="col-md-9 col-sm-10 input-group">
    <label className="upload-label" htmlFor="back_id_image">
      {formData.back_id_image ? "آپلود دوباره" : "آپلود"}
    </label>
    <input
      type="file"
      className="file-upload"
      id="back_id_image"
      onChange={handleFileChange}
      name="back_id_image"
    />
    {formData.back_id_image && (
      <img
        src={
          formData.back_id_image instanceof File
            ? URL.createObjectURL(formData.back_id_image)
            : formData.back_id_image
        }
        alt=" "
        className="image-preview"
      />
    )}
  </div>
</div>

<div className="form-group">
  <label className="col-md-3 col-sm-2 control-label" htmlFor="id_fish_image">
    عکس رسید کارت ملی
  </label>
  <div className="col-md-9 col-sm-10 input-group">
    <label className="upload-label" htmlFor="id_fish_image">
      {formData.id_fish_image ? "آپلود دوباره" : "آپلود"}
    </label>
    <input
      type="file"
      className="file-upload"
      id="id_fish_image"
      onChange={handleFileChange}
      name="id_fish_image"
    />
    {formData.id_fish_image && (
      <img
        src={
          formData.id_fish_image instanceof File
            ? URL.createObjectURL(formData.id_fish_image)
            : formData.id_fish_image
        }
        alt=" "
        className="image-preview"
      />
    )}
  </div>
</div>

<div className="form-group">
            <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div className="col-md-8 col-sm-8">
                    <input type="text" className="form-control" id="postal_code" placeholder="کد پستی" value={formData.postal_code} onChange={handleChange} name="postal_code"/>
                </div>
                <label className="control-label" for="postal_code" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> کد پستی
        </label>
            </div>
            </div>
            <div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div className="col-md-8 col-sm-8">
            <div className="row">
            <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="birth_day" value={formData.birth_day} onChange={handleChange} name="birth_day">
                        <option value="" disabled>روز</option>
                        {daysInMonth.map(day => (
                            <option key={day} value={day}>{day}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="birth_month" value={formData.birth_month} onChange={handleChange} name="birth_month">
                        <option value="" disabled>ماه</option>
                        {months.map(month => (
                            <option key={month.value} value={month.value}>{month.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="birth_year" value={formData.birth_year} onChange={handleChange} name="birth_year">
                        <option value="" disabled>سال</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
        <label className="control-label" for="birth_date" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> تاریخ تولد
        </label>
    </div>
</div>


<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '25px'}}>
        <div className="col-md-8 col-sm-8">
            <select className="form-control" id="marital_status" value={formData.marital_status} onChange={handleChange} name="marital_status">
                <option value="" disabled>وضعیت تاهل را انتخاب کنید</option>
                <option value="مجرد">مجرد</option>
                <option value="متاهل">متاهل</option>
            </select>
        </div>
        <label className="control-label" for="marital_status" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> وضعیت تاهل
        </label>
    </div>
</div>

{formData.marital_status === "متاهل" && (
    <div className="form-group">
        <div className="col-md-4 col-sm-4" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="col-md-8 col-sm-8">
                <input type="text" className="form-control" id="spouse_name" placeholder="نام همسر" value={formData.spouse_name} onChange={handleChange} name="spouse_name"/>
            </div>
            <label className="control-label" for="spouse_name" style={{ marginLeft: '10px' }}>نام همسر</label>
            
        </div>
    </div>
)}

{formData.marital_status === "متاهل" && (
    <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
        <label className="control-label" for="spouse_birth_date" style={{ marginLeft: '10px' }}>تاریخ تولد همسر</label>
        <div style={{ flex: '1' }}>
            <div className="row">
                <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="spouse_birth_year" value={formData.spouse_birth_year} onChange={handleChange} name="spouse_birth_year">
                        <option value="" disabled>سال</option>
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="spouse_birth_month" value={formData.spouse_birth_month} onChange={handleChange} name="spouse_birth_month">
                        <option value="" disabled>ماه</option>
                        {months.map(month => (
                            <option key={month.value} value={month.value}>{month.name}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-4 col-sm-4">
                    <select className="form-control" id="spouse_birth_day" value={formData.spouse_birth_day} onChange={handleChange} name="spouse_birth_day">
                        <option value="" disabled>روز</option>
                        {daysInMonth.map(day => (
                            <option key={day} value={day}>{day}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    </div>
)}


<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div className="col-md-8 col-sm-8">
            <div className="radio-group">
                <input className="check" type="radio" id="bounced_check_yes" name="bounced_check" value="yes" checked={formData.bounced_check === "yes"} onChange={handleChange} />
                <label for="bounced_check_yes">دارم</label>
                <input className="check" type="radio" id="bounced_check_no" name="bounced_check" value="no" checked={formData.bounced_check === "no"} onChange={handleChange} />
                <label for="bounced_check_no">ندارم</label>
            </div>
        </div>
        <label className="control-label" for="bounced_check" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> چک برگشتی
        </label>
    </div>
</div>
<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div className="col-md-8 col-sm-8">
            <div className="radio-group">
                <input className="check" type="radio" id="delayed_payment_yes" name="delayed_payment" value="yes" checked={formData.delayed_payment === "yes"} onChange={handleChange} />
                <label for="delayed_payment_yes">دارم</label>
                <input className="check" type="radio" id="delayed_payment_no" name="delayed_payment" value="no" checked={formData.delayed_payment === "no"} onChange={handleChange} />
                <label for="delayed_payment_no">ندارم</label>
            </div>
        </div>
        <label className="control-label" for="delayed_payment" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> قسط معوق
        </label>
    </div>
</div>
{formData.gender === "مرد" && (
<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div className="col-md-8 col-sm-8">
            <div className="radio-group">
                <input className="check" type="radio" id="military_service_status_yes" name="military_service_status" value="yes" checked={formData.military_service_status === "yes"} onChange={handleChange} />
                <label for="military_service_status_yes">دارم</label>
                <input className="check" type="radio" id="military_service_status_no" name="military_service_status" value="no" checked={formData.military_service_status === "no"} onChange={handleChange} />
                <label for="military_service_status_no">ندارم</label>
            </div>
        </div>
        <label className="control-label" for="military_service_status" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> پایان خدمت
        </label>
    </div>
</div>
)}

<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' , marginRight: '32px' }}>
        <div className="col-md-8 col-sm-8">
            <select className="form-control" id="job" value={formData.job} onChange={handleChange} name="job">
                <option value="" disabled>شغل خود را انتخاب کنید</option>
                <option value="کارمند ادارات دولتی">کارمند ادارات دولتی</option>
                <option value="کارمند ادارات خصوصی">کارمند ادارات خصوصی</option>
                <option value="بازنشسته دولتی">بازنشسته دولتی</option>
                <option value="بازنشسته تأمین اجتماعی">بازنشسته تأمین اجتماعی</option>
                <option value="خانه دار">خانه دار</option>
                <option value="دانشجو">دانشجو</option>
                <option value="آزاد">آزاد</option>
                <option value="نیرو های مسلح">نیرو های مسلح</option>
            </select>
        </div>
        <label className="control-label" for="job" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> شغل
        </label>
    </div>
</div>
<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '64px'  }}>
        <div className="col-md-8 col-sm-8">
            <select className="form-control" id="education" value={formData.education} onChange={handleChange} name="education">
                <option value="" disabled>انتخاب کنید</option>
                <option value="سیکل">سیکل</option>
                <option value="دیپلم">دیپلم</option>
                <option value="کارشناسی">کارشناسی</option>
                <option value="کارشناسی ارشد">کارشناسی ارشد</option>
                <option value="دکتری">دکتری</option>
            </select>
        </div>
        <label className="control-label" for="education" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> آخرین مدرک دریافتی
        </label>
    </div>
</div>

<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '36px'  }}>
        <div className="col-md-8 col-sm-8">
            <input type="text" className="form-control" id="second_mobile_number" placeholder="شماره همراه دوم" value={formData.second_mobile_number} onChange={handleSecondMobileNumberChange} name="second_mobile_number"/>
        </div>
        <label className="control-label" for="second_mobile_number" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> شماره همراه دوم
        </label>
    </div>
</div>

<div className="form-group">
    <div className="col-md-4 col-sm-4" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: '36px'  }}>
        <div className="col-md-8 col-sm-8">
            <input type="text" className="form-control" id="landline_number" placeholder="شماره تلفن ثابت" value={formData.landline_number} onChange={handleLandlineNumberChange} name="landline_number"/>
        </div>
        <label className="control-label" for="landline_number" style={{ margin: 0 }}>
            <span className="required" style={{ marginLeft: '5px' }}>*</span> شماره تلفن ثابت
        </label>
    </div>
</div>
<div className="form-group">
    <label className="control-label">
        <input
            type="checkbox"
            checked={formData.firstApprove === 'true'}
            onChange={(e) => setFormData({
                ...formData,
                firstApprove: e.target.checked ? 'true' : ''
            })}
        />
        <span> صحت تمام اطلاعات وارد شده را تایید میکنم</span>
    </label>
</div>


<div className="form-group">
    <div className="col-md-offset-3 col-sm-offset-2 col-sm-3">
        <button type="submit" className="btn btn-default">تایید</button>
    </div>
</div>
</form>
</main>
<Footer />
{showToast && (
        <Toast
            message1={toastMessage1}
            message2={toastMessage2}
            onClose={() => setShowToast(false)}
        />
    )}
</div>

    );
};

export default SecondPage;
