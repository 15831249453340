import React, { useState, useEffect, useRef } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Loading from '../Loading'; 
import CryptoJS from 'crypto-js';

function Login({ onLogin, stage }) {
    const [loading, setLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [ECode, setECode] = useState(''); // یک فیلد برای ورود کد
    const [cooldown, setCooldown] = useState(0); // مدیریت کول داون
    const [buttonText, setButtonText] = useState('دریافت کد'); // مدیریت متن دکمه
    const [errorIndex, setErrorIndex] = useState(null); // مدیریت خطا برای ورودی‌های کد
    const inputRefs = useRef([]); // ذخیره رفرنس برای هر ورودی
    const [phoneError, setPhoneError] = useState(false); // مدیریت خطای ورودی شماره تلفن
    const secretKey = "ExaByte13830908"
const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (/^[0-9]*$/.test(value)) { // اگر ورودی عدد است
            setPhoneNumber(value);
            setPhoneError(false); // حذف خطا
        } else {
            setPhoneError(true); // اعمال خطا
            setTimeout(() => setPhoneError(false), 2000); // حذف خطا بعد از ۲ ثانیه
        }
    };
    
    const [checked, setchecked] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                        setchecked(true)
                          navigate('/dashboard')
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  } 
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
      }, []);
      useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (stage === '3') {
                    navigate('/next-page'); // انتقال به صفحه /
                }else  if (stage === '2') {
                    navigate('/second-page'); // انتقال به صفحه /
                }  else  if (stage === '4') {
                    navigate('/upload-video'); // انتقال به صفحه /
                } else  if (stage === '5') {
                    navigate('/wait'); // انتقال به صفحه /
                } else if (stage === '6') {
                    navigate('/code');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();

    }, [checked])
   
    // انتقال به باکس بعدی در صورت وارد کردن عدد
    const handleOtpChange = (e) => {
        setECode(e.target.value); // امکان وارد کردن هر نوع کاراکتری
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://141.11.21.74:5000/api/loginn', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone_number: phoneNumber, code: ECode }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            alert('خوش آمدید به داشبورد!');
            try {
                Cookies.set('coder', ECode);
                setTimeout(3000, console.log(Cookies.get('code')));
            } catch (error) {
                console.error('Error:', error);
            }
            navigate('/dashboard');
        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }
    };
    if (loading) {
        return <Loading />; // نمایش لودینگ تا زمانی که داده‌ها بارگذاری شوند
      }
    return (
        <div className="Login">
            <div className="wrapper">
                <div className="form-group">
                    <div className="phone-and-button">
                        <div className="input-field1">
                            <input
                                type="text"
                                placeholder="شماره تلفن"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                className={phoneError ? 'error' : ''}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="code-and-label">
                        <div className="code-inputs">
                            <input
                                type="text"
                                placeholder="کد اشتراک خود را وارد کنید"
                                value={ECode}
                                onChange={handleOtpChange}
                                className="code-input"
                                required
                            />
                        </div>
                    </div>
                    <button className="full-width-button" onClick={handleLogin}>
                        ورود
                    </button>
                </div>
            </div>
        </div>
    );
}
export default Login;
