import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Toast from './popup';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './wait.css';
import CryptoJS from 'crypto-js';
import { FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';   // Import success icon

const Wait = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage1, setToastMessage1] = useState('');
    const [toastMessage2, setToastMessage2] = useState('');
    const [formData, setFormData] = useState({
        gender: ''
    });
    const secretKey = "ExaByte13830908"
const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
const enphone = Cookies.get('phone');
const phoner = decrypt(enphone, secretKey);
 const [checked, setchecked] = useState([]);
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    console.log("WTF")
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                            try {
                                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                                const responseBody = await response.text();
                                if (!response.ok) {
                                    const errorData = JSON.parse(responseBody);
                                    throw new Error(errorData.error);
                                }
                                const data1 = JSON.parse(responseBody);
                                const stage1 = data1.Stage;
                                if (stage1 === '7'){
                                setchecked(true)
                                navigate('/dashboard')
                                }
                            } catch (error) {

                            }
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
    }, []);
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (stage === '2') {
                    navigate('/second-page'); // انتقال به صفحه /
                }else if (stage === '3') {
                    navigate('/next-page'); // انتقال به صفحه /
                }  
                else if (stage === '4') {
                    navigate('/upload-video'); // انتقال به صفحه /
                } else if (stage === '6') {
                    navigate('/code');
                }  else if (stage === '7') {
                    navigate('/login');
                } 
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();
    }, [checked])
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-info?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setFormData((prevState) => ({
                    ...prevState,
                    gender: data.gender,
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, []);
const username = Cookies.get('TokenN');
    return (
        <div className="wait-page">
            <Header userName={username} activeTab="انتظار" />
            <main className="content5">
                <div className="centered">
                    {/* متن اصلی کاربر */}
                    <p className="username-text">{formData.gender === 'مرد' ? 'جناب آقای' : 'سرکار خانم'} {username}</p>

                    {/* آیکون موفقیت به همراه متن */}
                    <div className="success-info">
                        <FaCheckCircle className="success-icon" />
                        <p className="success-message">اطلاعات شما با موفقیت ثبت شد.</p>
                    </div>

                    <p className="pending-message">بعد از بررسی اطلاعات کارشناسان ما با شما تماس خواهند گرفت.</p>
                </div>

                {/* آیکون در انتظار بررسی */}
                <div className="icon-container">
                    <FaHourglassHalf className="pending-icon" />
                    <p className="pending-text">در انتظار بررسی</p>
                </div>
            </main>
            <Footer />
        </div>
    );
};
export default Wait;
