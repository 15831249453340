import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Toast from './popup';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './NextPage.css'
import Loading from '../Loading'; // کامپوننت لودینگ
import CryptoJS from 'crypto-js';
import { FaUser, FaIdCard, FaHome, FaPhone, FaCalendarAlt, FaHeart, FaMoneyCheckAlt, FaMedal, FaBriefcase, FaGraduationCap, FaMapMarkerAlt, FaCheck, FaEdit } from 'react-icons/fa';
 
const NextPage = (stage, fatherName, nationalId, province, city, fullAddress, frontIdImage, backIdImage, idFishImage, postalCode, birthDate, maritalStatus, spouseName, spouseBirthDate, bouncedCheck, delayedPayment, militaryServiceStatus, job, education, secondMobileNumber, landlineNumber) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true); 
    const [showToast, setShowToast] = useState(false);
    const [toastMessage1, setToastMessage1] = useState('');
    const [toastMessage2, setToastMessage2] = useState('');
    const [checked, setchecked] = useState([]);
    const [formData, setFormData] = useState({
        gender: '',
        father_name: '',
        national_id: '',
        province: '',
        city: '',
        full_address: '',
        front_id_image: '',
        back_id_image: '',
        id_fish_image: '',
        postal_code: '',
        birth_year: '', birth_month: '', birth_day: '', birth_date: '',
        marital_status: '',
        spouse_name: '',
        spouse_birth_year: '', spouse_birth_month: '', spouse_birth_day: '', spouse_birth_date: '',
        bounced_check: '',
        delayed_payment: '',
        military_service_status: '',
        job: '',
        education: '',
        second_mobile_number: '',
        landline_number: '',
        firstApprove: '',
        stage: '2'
    });
    const [username, setUsername] = useState('');
    const secretKey = "ExaByte13830908"
  const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
    const enphone = Cookies.get('phone');
    const phoner = decrypt(enphone, secretKey);
    useEffect(() => {
        const getUsername = () => {
          const username = Cookies.get('TokenN');
          setUsername(username);
        };
        setTimeout(getUsername, 500);
      }, []);
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    console.log("WTF")
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                            try {
                                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                                const responseBody = await response.text();
                                if (!response.ok) {
                                    const errorData = JSON.parse(responseBody);
                                    throw new Error(errorData.error);
                                }
                                const data1 = JSON.parse(responseBody);
                                const stage1 = data1.Stage;
                                if (stage1 === '7'){
                                setchecked(true)
                                navigate('/dashboard')
                                }
                            } catch (error) {

                            }
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
    }, []);
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                    console.log(stage)
                if (stage === '2') {
                    navigate('/second-page'); // انتقال به صفحه /
                } else if (stage === '4') {
                    navigate('/upload-video'); // انتقال به صفحه /
                } else  if (stage === '5') {
                    navigate('/wait'); // انتقال به صفحه /
                } else if (stage === '6') {
                    navigate('/code');
                }  else if (stage === '7') {
                    navigate('/login');
                } 
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();
    }, [checked])
   
    const [imageUrl, setImageUrl] = useState(null);
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-info?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                
                // تبدیل تصاویر از base64 به URL با تنظیم MIME type
                const frontIdImageUrl = data.front_id_image ? `data:image/jpeg;base64,${data.front_id_image}` : null;
                const backIdImageUrl = data.back_id_image ? `data:image/jpeg;base64,${data.back_id_image}` : null;
                const idFishImageUrl = data.id_fish_image ? `data:image/jpeg;base64,${data.id_fish_image}` : null;
    
                setFormData((prevState) => ({
                    ...prevState,
                    gender: data.gender,
                    father_name: data.father_name,
                    national_id: data.national_id,
                    province: data.province,
                    city: data.city,
                    full_address: data.full_address,
                    front_id_image: frontIdImageUrl,
                    back_id_image: backIdImageUrl,
                    id_fish_image: idFishImageUrl,
                    postal_code: data.postal_code,
                    birth_date: data.birth_date,
                    marital_status: data.marital_status,
                    spouse_name: data.spouse_name,
                    spouse_birth_date: data.spouse_birth_date,
                    bounced_check: data.bounced_check,
                    delayed_payment: data.delayed_payment,
                    military_service_status: data.military_service_status,
                    job: data.job,
                    education: data.education,
                    second_mobile_number: data.second_mobile_number,
                    landline_number: data.landline_number
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        checkk();
    }, []);
    
    
    const handleConfirm = () => {
        // کد تایید اطلاعات
      };
      
      const handleEdit = async (e) => {
        // کد تغییر اطلاعات
        const phone_number = phoner;
        const stage = "2";
        try {
            const response = await fetch('http://141.11.21.74:5000/api/edit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone_number, stage })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            alert('فرم با موفقیت ارسال شد!');
        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }

        // هدایت به صفحه بعد و ارسال داده‌های فرم
        navigate('/second-page', { state: { ...formData } });
      };
      const handleSub = async (e) => {
        // کد تغییر اطلاعات
        const phone_number = phoner;
        const stage = "4";
        try {
            const response = await fetch('http://141.11.21.74:5000/api/edit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone_number, stage })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            alert('فرم با موفقیت ارسال شد!');
        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }

        // هدایت به صفحه بعد و ارسال داده‌های فرم
        navigate('/upload-video', { state: { ...formData } });
      };
      const formattedBirthDate = formData.birth_date.split('T')[0].split('-').reverse().join('-'); // فرمت تاریخ به "DD-MM-YYYY"
      if (loading) {
        return <Loading />; // نمایش لودینگ تا زمانی که داده‌ها بارگذاری شوند
      }
      return (
<div className="next-page">
    <Header userName={username} activeTab = "بازبینی"/>
    <main className="content2">
    <div className="centered">
        <p>{formData.gender === 'مرد' ? 'جناب آقای' : 'سرکار خانم'} {username} لطفا اطلاعات وارد شده خود را دوباره بررسی کنید و در صورت مغایرت، آنها را ویرایش کنید</p>
    </div>
    <div className="grid-container">
    <div>
        <FaMapMarkerAlt className="icon" />
        <p>استان: {formData.province}</p>
    </div>
    <div>
        <FaUser className="icon" />
        <p>نام پدر: {formData.father_name}</p>
    </div>
    <div>
        <FaUser className="icon" />
        <p>نام: {username}</p>
    </div>
    <div>
        <FaHome className="icon" />
        <p>آدرس: {formData.full_address}</p>
    </div>
    <div>
        <FaCalendarAlt className="icon" />
        <p>تاریخ تولد: {formattedBirthDate}</p>
    </div>
    <div>
        <FaIdCard className="icon" />
        <p>کد ملی: {formData.national_id}</p>
    </div>
    {formData.spouse_birth_date !== "" && formData.spouse_birth_date !== '0000-00-00' && (
        <div>
            <FaCalendarAlt className="icon" />
            <p>تاریخ تولد همسر: {formData.spouse_birth_date}</p>
        </div>
    )}
    {formData.spouse_name !== "" && (
        <div>
            <FaHeart className="icon" />
            <p>نام همسر: {formData.spouse_name}</p>
        </div>
    )}
    <div>
        <FaHeart className="icon" />
        <p>وضعیت تاهل: {formData.marital_status}</p>
    </div>
    <div>
        <FaMoneyCheckAlt className="icon" />
        <p>قسط عقب افتاده: {formData.delayed_payment === 'yes' ? 'دارم' : 'ندارم'}</p>
    </div>
    <div>
        <FaMedal className="icon" />
        <p>پایان خدمت: {formData.military_service_status === 'yes' ? 'دارم' : 'ندارم'}</p>
    </div>
    <div>
        <FaPhone className="icon" />
        <p>شماره تلفن ثابت: {formData.landline_number}</p>
    </div>
    <div>
        <FaGraduationCap className="icon" />
        <p>تحصیلات: {formData.education}</p>
    </div>
    <div>
        <FaBriefcase className="icon" />
        <p>شغل: {formData.job}</p>
    </div>


    <div>
        <FaPhone className="icon" />
        <p>شماره موبایل دوم: {formData.second_mobile_number}</p>
    </div>
</div>
    <div className="button-container">
  <button onClick={handleSub}>
    <FaCheck className="icon-button" /> تایید
  </button>
  <button onClick={handleEdit}>
    <FaEdit className="icon-button" /> تغییر اطلاعات
  </button>
</div>
</main>

<Footer />
{showToast && (
        <Toast
            message1={toastMessage1}
            message2={toastMessage2}
            onClose={() => setShowToast(false)}
        />
    )}
</div>

    );
};

export default NextPage;
