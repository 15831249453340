import React, { useState, useEffect, useRef } from 'react';
import './SubscriptionCode.css'; // فایل استایل‌ها
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCopy, faPrint, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './LoginForm.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loading from '../Loading'; // کامپوننت لودینگ
import CryptoJS from 'crypto-js';

const SubscriptionCode = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const fullCode = 'ABCD-1234-EFGH'; // کد نمونه
    const [subscriptionCode, setSubscriptionCode] = useState('');
      const [checked, setchecked] = useState([]);
      const secretKey = "ExaByte13830908"
    const decrypt = (encryptedBase64, secretKey) => {
    if (encryptedBase64 !== '' && encryptedBase64 !== undefined && encryptedBase64) { 
    // ابتدا base64 را به فرمت اصلی رمزنگاری شده تبدیل می‌کنیم
    const encryptedText = CryptoJS.enc.Base64.parse(encryptedBase64).toString(CryptoJS.enc.Utf8);
    
    // سپس با استفاده از کلید مخفی، رمزگشایی را انجام می‌دهیم
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey);
    
    // تبدیل به متن ساده
    const originalText = decrypted.toString(CryptoJS.enc.Utf8);   
    return originalText
    } else {
        return "bo";
    }
};
    const enphone = Cookies.get('phone');
    const phoner = decrypt(enphone, secretKey);
    useEffect(() => {
        const CheckLogined = async () => {
            const ctoken = Cookies.get('token');
            try {
                const response = await fetch('http://141.11.21.74:5000/api/token-check', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ phone_number: phoner, token: ctoken })
                });
                const data = await response.json();
                if (!data.success) {
                    console.log("WTF")
                    navigate('/'); // انتقال به صفحه /
                } else {
                    const code = Cookies.get('coder');
                    try {
                      const response1 = await fetch('http://141.11.21.74:5000/api/loginn', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ phone_number: phoner, code: code }),
                      });
                  
                      if (response1.ok) {
                            try {
                                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                                const responseBody = await response.text();
                                if (!response.ok) {
                                    const errorData = JSON.parse(responseBody);
                                    throw new Error(errorData.error);
                                }
                                const data1 = JSON.parse(responseBody);
                                const stage1 = data1.Stage;
                                if (stage1 === '7'){
                                setchecked(true)
                                navigate('/dashboard')
                                }
                            } catch (error) {

                            }
                      } else {
                        setchecked(false)
                      }
                  } catch (error) {
                    setchecked(false)
                      console.error('Error:', error);
                      alert(`مشکلی پیش آمد: ${error.message}`);
                  }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        CheckLogined();
    }, []);
    useEffect(() => {
        const checkk = async () => {
            try {
                const response = await fetch(`http://141.11.21.74:5000/api/get-stage?phone_number=${phoner}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const responseBody = await response.text();
                if (!response.ok) {
                    const errorData = JSON.parse(responseBody);
                    throw new Error(errorData.error);
                }
                const data = JSON.parse(responseBody);
                const stage = data.Stage;
                if (stage === '2') {
                    navigate('/second-page'); // انتقال به صفحه /
                } else if (stage === '3') {
                    navigate('/next-page'); // انتقال به صفحه /
                } else if (stage === '4') {
                    navigate('/upload-video'); // انتقال به صفحه /
                } else  if (stage === '5') {
                    navigate('/wait'); // انتقال به صفحه /
                }  else if (stage === '7') {
                    navigate('/login');
                } 
            } catch (error) {
                console.error('Error:', error);
            }
            setLoading(false)
        };
        checkk();
    }, [checked])
   
    useEffect(() => {
        // برای جلوگیری از اضافه شدن به کد قبلی، از کد کامل به یکباره استفاده کن
        setSubscriptionCode(fullCode);
    }, []); // از useEffect استفاده می‌کنیم تا فقط یکبار اجرا شود

    const copyToClipboard = () => {
        navigator.clipboard.writeText(fullCode);
        alert('کد اشتراک کپی شد!');
    };

    const printCode = () => {
        window.print();
    };
    const handleEdit = async (e) => {
        // کد تغییر اطلاعات
        const phone_number = phoner;
        const stage = "7";
        try {
            const response = await fetch('http://141.11.21.74:5000/api/edit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phone_number, stage })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

        } catch (error) {
            console.error('Error:', error);
            alert(`مشکلی پیش آمد: ${error.message}`);
        }

        // هدایت به صفحه بعد و ارسال داده‌های فرم
      };
    const goToDashboard = () => {
        handleEdit()
        navigate('/login');
    };
    if (loading) {
        return <Loading />; // نمایش لودینگ تا زمانی که داده‌ها بارگذاری شوند
      }
    return (
        <div className="sub-code">
            <main className="content6">
                <div className="subscription-container">
                <h1>
                        <FontAwesomeIcon icon={faCheckCircle} className="check-icon" /> اطلاعات شما تایید شد
                    </h1>
                    <p>کد اشتراک شما:</p>
                    <div className="code-box">
                        {subscriptionCode}
                        <FontAwesomeIcon icon={faCopy} onClick={copyToClipboard} className="copy-icon" />
                    </div>
                    <div className="button-group">
                    <button onClick={goToDashboard} className="dashboard-btn">
                            <FontAwesomeIcon icon={faArrowLeft} /> ورود به داشبورد
                        </button>
                        <button onClick={printCode} className="print-btn">
                            <FontAwesomeIcon icon={faPrint} /> پرینت
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SubscriptionCode;
